import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Clock from "../Clock";
import { SmallButton } from "../Button";
import themes from "../../libs/themes";
import SessionInfo from "../SessionInfo";
import HmiInfo from "../HmiInfo";
import UserInfo from "../UserInfo";
import HeaderMenu from "../HeaderMenu";
import { useHistory } from "react-router-dom";

/**
 * Header
 *
 * @param children
 * @param history
 * @param hasHmiInfo
 * @param hasSessionInfo
 * @param hasUserInfo
 * @param hasClock
 * @returns {*}
 * @constructor
 */
function Header({
  children,
  hasHmiInfo,
  hasSessionInfo,
  hasUserInfo,
  hasClock,
  hasMenu,
}) {
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();

  return (
    <div className="columns">
      {/* HMI INFO */}
      {hasHmiInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <HmiInfo />
        </div>
      )}
      {/* USER INFO */}
      {hasUserInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/worker/dashboard`)}
          >
            <UserInfo />
          </div>
        </div>
      )}
      {/* SESSION INFO */}
      {hasSessionInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/worker/dashboard`)}
          >
            <SessionInfo />
          </div>
        </div>
      )}
      {/* CLOCK */}
      {hasClock && (
        <div className="column is-4 has-text-centered">
          <Clock />
        </div>
      )}
      {hasMenu && (
        <div className="column is-1 is-offset-3">
          <SmallButton
            isFullWidth
            theme={themes.default.buttons}
            data-cy="application-button-menu"
            onClick={() => setShowMenu(true)}
          >
            <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
          </SmallButton>
        </div>
      )}
      {showMenu && <HeaderMenu onClose={() => setShowMenu(false)} />}
      {children}
    </div>
  );
}

Header.defaultProps = {
  hasSessionInfo: true,
  hasClock: true,
  hasUserInfo: false,
  hasMenu: false,
};
Header.propTypes = {
  hasMenu: PropTypes.bool,
  hasSessionInfo: PropTypes.bool,
  hasClock: PropTypes.bool,
  hasUserInfo: PropTypes.bool,
  children: PropTypes.element,
};
export default Header;
