export const RESET_PHASE = "RESET_PHASE";
export const RESET_CONFIG = "RESET_CONFIG";

export function resetPhase() {
  return { type: RESET_PHASE };
}

export function resetConfig() {
  return { type: RESET_CONFIG };
}

export const SET_PICKED = "SET_PICKED";
export const SET_PRODUCED = "SET_PRODUCED";
export const SET_SCRAP = "SET_SCRAP";
export const SET_NOTES = "SET_NOTES";
export const SET_ERROR = "SET_ERROR";
export const SET_STATUS = "SET_STATUS";

export function setPicked(value = null) {
  return { type: SET_PICKED, value };
}

export function setProduced(value = null) {
  return { type: SET_PRODUCED, value };
}

export function setScrap(value = null) {
  return { type: SET_SCRAP, value };
}

export function setNotes(value = null) {
  return { type: SET_NOTES, value };
}

export function setError(value = null) {
  return { type: SET_ERROR, value };
}

export function setStatus(value = null) {
  return { type: SET_STATUS, value };
}

// Manual session
export const SET_TIME = "SET_TIME";
export function setTime(value = null) {
  return { type: SET_TIME, value };
}
