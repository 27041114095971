import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import Background from "../../components/Layout";
import themes from "../../libs/themes";
/**
 * HMI Not configured
 *
 * @description Show an error about configuration
 * @constructor
 */
function NotConfigured() {
  const history = useHistory();
  const theme = themes.default;
  const config = useSelector(({ config }) => config);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HMI Configuration Error - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          foot={
            <div className="columns">
              <div className="column is-6">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  onClick={() => history.push("/")}
                >
                  <FormattedMessage
                    id="app.shared.home"
                    defaultMessage="Home"
                  />
                </Button>
              </div>
              <div className="column is-6">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  onClick={() => window.location.reload()}
                >
                  <FormattedMessage
                    id="app.shared.retry"
                    defaultMessage="Retry"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns has-text-centered">
              <div className="column">
                <h2 className="title is-2 ">
                  <FormattedMessage
                    id="app.pages.errors.notconfigured"
                    defaultMessage="Sorry, this HMI seems not configured."
                  />
                </h2>
                <p className="subtitle is-4">{JSON.stringify(config)}</p>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default NotConfigured;
