import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useSubscription } from "@apollo/client";

import { statuses } from "../../../../libs/themes";

import SetStatus from "../../../../components/SetStatus";
import CheckStatus from "../../../../components/CheckStatus";
import { loader } from "graphql.macro";
import { useSelector } from "react-redux";
const CYCLE_RECEIVED = loader("./Wrapper.subscription.graphql");

function Wrapper({ status, children }) {
  const { phaseId } = useParams();
  const user = useSelector(({ user }) => user);

  const variables = { phaseId, workerId: user.worker._id };

  useSubscription(CYCLE_RECEIVED, { variables });

  const theme = statuses[status || "INTERRUPT"];
  return (
    <ThemeProvider theme={theme}>
      <CheckStatus>
        <SetStatus status={status}>{children}</SetStatus>
      </CheckStatus>
    </ThemeProvider>
  );
}

Wrapper.defaultProps = {};
Wrapper.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
export default Wrapper;
