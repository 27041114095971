import React from "react";
import { useQuery } from "@apollo/client";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../LoadingIndicator";
import styled from "styled-components";
import Button from "../../components/Button";
import { loader } from "graphql.macro";
import ContainerAction from "./components/ContainerAction";
import themes from "../../libs/themes";
import ListElemModal from "../../components/ListElemModal";
import Smiro from "../Smiro";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

const CONTAINER_ACTIONS = loader("./ContainerActions.query.graphql");

const PopupButton = styled(Button)`
  background-color: #d9e0ec;
  height: 6.8rem;
  font-size: 2.8rem;
  width: 100%;
  i {
    transform: translateX(-6px);
  }
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
  &:hover {
    background-color: #ccc;
  }
`;

function ContainerActions({ container, isPopup, isLarge }) {
  const history = useHistory();
  const { data, loading, error } = useQuery(CONTAINER_ACTIONS, {});
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  if (container == null) return <></>;

  const { containerTypes } = data;
  function canAction(action) {
    var type = containerTypes.find((t) => t.name === container.smiroType);
    if (type) {
      return type.actions.includes(action);
    }
    return false;
  }
  function scanRfid(e) {
    e.stopPropagation();
    container._id &&
      history.push(`/application/container-rfid/${container._id}`);
  }
  return isPopup ? (
    <>
      <ListElemModal
        className="icon"
        head={
          <p className="title is-4 w500 has-text-centered has-text-white">
            Smiro
          </p>
        }
        button={
          <PopupButton
            isSmall={true}
            theme={themes.default.buttons}
            data-cy="application-button-picked"
          >
            <i
              className="icon-connect"
              style={{ marginRight: isLarge ? "9px" : "0" }}
            />
            {isLarge && <>RFID</>}
          </PopupButton>
        }
      >
        <div style={{ width: "100%" }}>
          <Smiro centered={true} scanRfid={scanRfid} />
          <div
            className="columns"
            style={{
              position: "absolute",
              bottom: "4.4rem",
              right: "2.4rem",
              width: "calc(100% - 2.4rem)",
            }}
          >
            {canAction("open") && (
              <ContainerAction
                action="open"
                fgColor="#fff"
                container={container}
                type="secondary"
              >
                <i className="icon-unlock" />
                <FormattedMessage
                  id="container.actions.open"
                  defaultMessage="Apri"
                />
              </ContainerAction>
            )}
            {canAction("close") && (
              <ContainerAction
                action="close"
                fgColor="#ƒff"
                container={container}
                type="secondary"
              >
                <i className="icon-giphy-lock" />{" "}
                <FormattedMessage
                  id="container.actions.close"
                  defaultMessage="Chiudi"
                />
              </ContainerAction>
            )}
            {canAction("lighton") && (
              <ContainerAction
                action="lighton"
                fgColor="#ƒff"
                container={container}
                type="secondary"
              >
                <i className="icon-light" />{" "}
                <FormattedMessage
                  id="container.actions.lighton"
                  defaultMessage="Trova"
                />
              </ContainerAction>
            )}
          </div>
        </div>
      </ListElemModal>
    </>
  ) : (
    <>
      {!isPopup && (
        <Button
          isFullWidth
          isSmall={true}
          theme={themes.primary.buttons}
          onClick={scanRfid}
          style={{ marginBottom: "0.8rem" }}
        >
          <FormattedMessage
            id="container_content.select_item"
            defaultMessage="Scan Tag"
          />
        </Button>
      )}
      {JSON.stringify(container)}
      TEST
      {canAction("open") && (
        <ContainerAction action="open" container={container} type="secondary">
          <i className="icon-unlock" />
        </ContainerAction>
      )}
      {canAction("close") && (
        <ContainerAction action="close" container={container} type="secondary">
          <i className="icon-giphy-lock" />
        </ContainerAction>
      )}
      {canAction("lighton") && (
        <ContainerAction
          action="lighton"
          container={container}
          type="secondary"
        >
          <i className="icon-light" />
        </ContainerAction>
      )}
    </>
  );
}

ContainerActions.defaultProps = {};
ContainerActions.propTypes = {};
export default ContainerActions;
