import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import styled, { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";

import Button, { SmallButton } from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import ListElement from "../../../components/ListElement";
import Title from "../../../components/Title";
import themes from "../../../libs/themes";
import nativeChannel from "../../../nativeChannel";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../libs/error-logger";
import toast from "../../../libs/toast";
import { setLoading } from "../../../actions";

const SCAN_PRODUCTION = loader("./ScanProduction.query.graphql");
const SCAN_SERIALNUMBER = loader("./ScanSerialnumber.mutation.graphql");
const CYCLE_SUBSCRIPTION = loader("./Cycles.subscription.graphql");

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 1.2rem 0.4rem 0.9rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }

  &.text-only {
    font-weight: 600;
    padding-right: 0.9rem;
    text-transform: uppercase;
  }

  .progress {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.14);
    z-index: -1;
  }
`;

function ScanProduction() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { phaseId, sessionId, stepId } = useParams();
  const history = useHistory();
  const variables = { sessionId };
  const { data, loading, error } = useQuery(SCAN_PRODUCTION, { variables });
  const [mutate] = useMutation(SCAN_SERIALNUMBER, {
    variables,
    refetchQueries: [{ query: SCAN_PRODUCTION, variables }],
  });
  useSubscription(CYCLE_SUBSCRIPTION, {
    variables,
  });

  const [lastBarcode, setLastBarcode] = useState();
  const [scanType, setScanType] = useState();
  console.log("cycles", data);

  const theme = themes.productionBlue;

  useEffect(() => {
    if (scanType) {
      nativeChannel.send("read_barcode_multiple");
      nativeChannel.on("barcode", onBarcode);
    } else {
      nativeChannel.send("read_barcode_stop");
      nativeChannel.off("barcode", onBarcode);
    }
    return () => {
      window.onBarcode = null;
      nativeChannel.off("barcode", onBarcode);
    };
  }, [scanType]);

  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;

  function onBarcodeSearch(type) {
    setScanType(type);
  }

  function onBarcodeStop() {
    nativeChannel.send("close_barcode");
    setScanType(null);
  }

  async function onBarcode(barcode) {
    console.log("onBarcode", barcode);
    dispatch(setLoading(true));
    if (barcode != null && barcode != lastBarcode) {
      setLastBarcode(barcode);
      try {
        await mutate({
          variables: { serialnumberCode: barcode, type: scanType },
        });
        toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      } catch (error) {
        onError(error);
      }
      dispatch(setLoading(false));
    }
  }

  let producingSerialnumbers = [...session.producingSerialnumbers];
  // Reverse the list to have the last scanned item on top
  producingSerialnumbers = producingSerialnumbers.reverse();

  return (
    <>
      <Helmet>
        <title>Scan production - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasPhaseInfo>
              <div className="column is-1 is-offset-3">
                <SmallButton
                  isFullWidth
                  theme={themes.default.buttons}
                  data-cy="application-supply-settings"
                  onClick={() =>
                    history.push(
                      `/application/supply-settings/${phaseId}/${sessionId}/${stepId}`
                    )
                  }
                >
                  <FormattedMessage
                    id="app.pages.application.supply.settings.button"
                    defaultMessage="Settings"
                  />
                </SmallButton>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => {
                    nativeChannel.send("close_barcode");
                    history.goBack();
                  }}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../images/arrow-back-gray-icn.svg").default
                    }
                    alt=""
                  />
                </Button>
              </div>
              {scanType == null && !session._machine && (
                <div className="column is-4 is-offset-3">
                  <Button
                    isFullWidth
                    theme={themes.productionBlue.buttons}
                    onClick={() => {
                      onBarcodeSearch("produced");
                    }}
                    data-cy="application-button-next"
                  >
                    <i className="icon-barcode" />
                    &nbsp;
                    <FormattedMessage
                      id="app.pages.application.production.buttons.production_scan"
                      defaultMessage="Scan production"
                    />
                  </Button>
                </div>
              )}
              {scanType == null && (
                <div className="column is-4">
                  <Button
                    isFullWidth
                    theme={themes.primary.buttons}
                    onClick={() => {
                      onBarcodeSearch("scrap");
                    }}
                    data-cy="application-button-next"
                  >
                    <i className="icon-barcode-delete" />
                    &nbsp;
                    <FormattedMessage
                      id="app.pages.application.production.buttons.production_scan_scrap"
                      defaultMessage="Scan scrap"
                    />
                  </Button>
                </div>
              )}
              {scanType != null && (
                <div className="column is-4 is-offset-7">
                  <Button
                    isFullWidth
                    theme={themes.default.buttons}
                    onClick={() => {
                      onBarcodeStop();
                    }}
                    data-cy="application-button-next"
                  >
                    <i className="icon-barcode-delete" />
                    &nbsp;
                    <FormattedMessage
                      id="app.pages.application.production.buttons.production_scan_stop"
                      defaultMessage="Stop scan"
                    />
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <>
            <div className="columns">
              <div
                className="column has-text-centered"
                style={{ margin: "2rem 0" }}
              >
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.production.buttons.production_history"
                    defaultMessage="Pezzi prodotti"
                  />
                </Title.H1>
                <p className="subtitle is-6 fg-secondary">
                  <FormattedMessage
                    id="app.pages.welcome.button.operations.subtitle"
                    defaultMessage="Production list"
                  />
                  <br />
                </p>
              </div>
            </div>
            {producingSerialnumbers.length == 0 && (
              <Title.H2 style={{ textAlign: "center" }}>
                <FormattedMessage
                  id="app.pages.application.production.buttons.production_history.empty"
                  defaultMessage="No items in your production list"
                />
              </Title.H2>
            )}
            <div className="columns">
              <div className="column">
                {producingSerialnumbers.map((p) => {
                  return (
                    <ListElement
                      onClick={() => {}}
                      theme={
                        p.type == "scrap"
                          ? themes.errorRed.listElement
                          : themes.default.listElement
                      }
                      data-cy="application-supply-item"
                      withCircle={false}
                      /*data-cy-id={picking._id}*/
                    >
                      <div className="columns is-vcentered is-fullwidth is-marginless is-padding">
                        <div className="column">
                          <p className="subtitle is-6 is-marginless-top">
                            <Badge
                              className={
                                "text-only" +
                                (p.type == "scrap" ? " error" : "")
                              }
                            >
                              <FormattedMessage
                                id={
                                  "app.pages.application.production.badge." + p
                                }
                                defaultMessage={p.type}
                              />
                            </Badge>
                            <Badge className={p.type == "scrap" ? "error" : ""}>
                              <i className="icon-giphy-time" />
                              {p.timestamp.substring(11, 19)}
                            </Badge>
                            {/*<Badge className={p.type=="scrap"?"error":""}>
                                  <i className="icon-doc-text" /> L001
                                </Badge>
                                <Badge className={p.type=="scrap"?"error":""}>
                                  <i className="icon-product" /> Prodotto 1
                        </Badge>*/}
                          </p>
                          {/*item.code && <Badge><i className="icon-product" />
                        {item.code}</Badge>}
                      {container.batch && <Badge><i className="icon-product" />{container.batch.code}</Badge>}
        </p>*/}
                          <p className="title is-4">{p.serialnumber.code}</p>
                        </div>
                        {/*<div className="column is-1">
                      <RemoveButton
                        isFullWidth
                        theme={themes.welcome.buttons}
                        onClick={() =>
                          history.push(
                            `/application/scan-production/${phaseId}/${sessionId}`
                          )
                        }
                        data-cy="application-button-next"
                      >
                        <i className="icon-giphy-delete-white" />
                      </RemoveButton>
                      </div>*/}
                      </div>
                    </ListElement>
                  );
                })}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ScanProduction.defaultProps = {};
ScanProduction.propTypes = {};
export default ScanProduction;
