import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";
import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { setLoading, setNotes } from "../../../actions";
import { MediumButton } from "../../../components/Button";
import { withPhase } from "../../../hocs";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import StepHeader from "../../../components/StepHeader";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import onError from "../../../libs/error-logger";
import themes, { buttons } from "../../../libs/themes";
import CenteredLayout from "../../../layouts/CenteredLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import GenericError from "../../errors/GenericError";
import TextareaField from "../../../components/Fields/TextareaField";

const CLOSE_SESSION = loader("./Notes.mutation.graphql");
const REPORT_ISSUE = loader("./ReportIssue.mutation.graphql");
const NOTES_QUERY = loader("./Notes.query.graphql");

function Notes(props) {
  const { intl, status, dispatch, match, history } = props;
  const { phaseId, sessionId } = match.params;
  const inputEl = useRef(null);
  const [noteState, setNoteState] = useState("");
  const [closeSession] = useMutation(CLOSE_SESSION);
  const [reportIssue] = useMutation(REPORT_ISSUE);
  const variables = { sessionId: sessionId, phaseId: phaseId };
  const { loading, error, data } = useQuery(NOTES_QUERY, { variables });
  const { hmiConfiguration } = useSelector(({ config }) => config);

  useEffect(() => {
    if (hmiConfiguration.SKIP_DELIVERY_NOTES) {
      return history.push(`/application/end/${phaseId}`);
    }
    // Workaround to show keyboard after render
    setTimeout(() => {
      if (inputEl && inputEl.current) {
        inputEl.current.select();
      }
    }, 100);
  }, [inputEl]);

  const theme = themes.default;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const onSubmit = async () => {
    const { status, message } = props;
    const sessionData = { notes: noteState };
    dispatch(setLoading(true));
    try {
      if (status === "ERROR") {
        await reportIssue({ variables: { sessionId, message } });
      }
      await closeSession({
        variables: { sessionId, session: sessionData, status },
      });
      history.push(`/application/end/${phaseId}`);
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  };

  const onBack = () => {
    dispatch(setNotes());
    history.goBack();
  };

  const onChange = (value) => {
    setNoteState(value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notes - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.notes.title"
                    defaultMessage="Add a note"
                  />
                </Title.H1>
                <StepHeader />
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <TextareaField
                  key="notes"
                  autofocus={true}
                  cy="application-notes-input"
                  placeholder={intl.formatMessage({
                    id: "app.components.notes.placeholder",
                    defaultMessage: "Do you want to write a message?",
                  })}
                  defaultValue={noteState}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default compose(
  injectIntl,
  withPhase(),
  connect(({ delivery }) => ({
    status: delivery.status,
    message: delivery.error,
  }))
)(Notes);
