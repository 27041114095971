import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { useQuery } from "@apollo/client";

import Button from "../../../components/Button";
import Background from "../../../components/Layout";
import themes, { buttons } from "../../../libs/themes";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import Header from "../../../components/Header";
import StepHeader from "../../../components/StepHeader";

import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Hoverable from "../components/hoverable";
import CheckStatus from "../../../components/CheckStatus";
import { loader } from "graphql.macro";
const MENU = loader("./Menu.query.graphql");

const Column = styled.div`
  max-width: 100%;
`;
function Menu() {
  const { phaseId, sessionId } = useParams();
  const history = useHistory();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(MENU, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Menu - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={themes.neutralGrey}>
        <Background
          head={<Header hasMenu />}
          foot={
            <div className="columns">
              <div className="column is-4">
                <Button
                  isFullWidth
                  theme={themes.pauseAzure.buttons}
                  onClick={() =>
                    history.push(`/application/pause/${phaseId}/${sessionId}`)
                  }
                  data-cy="application-menu-pause"
                >
                  <FormattedMessage
                    id="app.shared.pause"
                    defaultMessage="Pause"
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-4">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  onClick={() =>
                    history.push(`/application/check/${phaseId}/${sessionId}`)
                  }
                  data-cy="application-menu-check"
                >
                  <FormattedMessage
                    id="app.shared.check"
                    defaultMessage="Deliver"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <CheckStatus>
            <div className="columns">
              <Column className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <FormattedMessage
                    id="app.shared.menu"
                    defaultMessage="Menu"
                  />
                </SmallTag>
                <Hoverable text={phase.sort + " :: " + phase.name}>
                  <Title.H1 isEllipsed>
                    {phase.sort} :: {phase.name}
                  </Title.H1>
                </Hoverable>
                <StepHeader />
              </Column>
            </div>
            <div className="columns has-text-centered is-multiline">
              <div className="column is-half">
                <Button
                  isFullWidth
                  theme={themes.materialViolet.buttons}
                  onClick={() =>
                    history.push(
                      `/application/material/${phaseId}/${sessionId}`
                    )
                  }
                  data-cy="application-menu-material"
                >
                  <i className="icon-status-material" />
                  <FormattedMessage
                    id="app.shared.material"
                    defaultMessage="Material"
                  />
                </Button>
              </div>
              <div className="column is-half">
                <Button
                  isFullWidth
                  theme={themes.setupYellow.buttons}
                  onClick={() =>
                    history.push(`/application/setup/${phaseId}/${sessionId}`)
                  }
                  data-cy="application-menu-setup"
                >
                  <i className="icon-status-setup" />
                  <FormattedMessage
                    id="app.shared.setup"
                    defaultMessage="Setup"
                  />
                </Button>
              </div>
              <div className="column is-half">
                <Button
                  isFullWidth
                  theme={themes.checkGreen.buttons}
                  onClick={() =>
                    history.push(
                      `/application/approval/${phaseId}/${sessionId}`
                    )
                  }
                  data-cy="application-menu-approval"
                >
                  <i className="icon-status-approval" />
                  <FormattedMessage
                    id="app.shared.approval"
                    defaultMessage="Approval"
                  />
                </Button>
              </div>
              <div className="column is-half">
                <Button
                  isFullWidth
                  theme={themes.productionBlue.buttons}
                  onClick={() =>
                    history.push(
                      `/application/production/${phaseId}/${sessionId}`
                    )
                  }
                  data-cy="application-menu-production"
                >
                  <i className="icon-status-production" />
                  <FormattedMessage
                    id="app.shared.production"
                    defaultMessage="Production"
                  />
                </Button>
              </div>
            </div>
          </CheckStatus>
        </Background>
      </ThemeProvider>
    </>
  );
}

Menu.defaultProps = {};
Menu.propTypes = {};

export default Menu;
