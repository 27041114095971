import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../components/Button";
import Background from "../../components/Layout";
import themes from "../../libs/themes";
import Title from "../../components/Title";

/**
 * @description Show a 404 page
 * @constructor
 */
function NotFound() {
  const history = useHistory();
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H3>
                  <FormattedMessage
                    id="app.shared.error"
                    defaultMessage="Error"
                  />
                </Title.H3>
              </div>
            </div>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../images/arrow-back-gray-icn.svg").default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  onClick={() => history.push("/")}
                >
                  <FormattedMessage
                    id="app.shared.home"
                    defaultMessage="Home"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H2>
                  <FormattedMessage
                    id="app.pages.errors.notfound"
                    defaultMessage="Sorry, page not found"
                  />
                </Title.H2>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default NotFound;
