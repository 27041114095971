import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Clock from "../../components/Clock";
import Header from "../../components/Header";
import Background from "../../components/Layout/Background";
import themes from "../../libs/themes";

import onError from "../../libs/error-logger";

import { loader } from "graphql.macro";
import { FormattedMessage } from "react-intl";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout.layout";
const STAMP_BADGE = loader("./Badge.mutation.graphql");
const LAST_STAMP = loader("./Badge.query.graphql");

function Badge() {
  const theme = themes.welcome;
  const [mutate] = useMutation(STAMP_BADGE);
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(({ user }) => user);

  const { data, loading, error } = useQuery(LAST_STAMP);

  if (loading)
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  if (error) return <div>Error!</div>;
  const lastStamping = data.lastStamping;

  if (!user || !user.worker) {
    return (
      <Redirect
        to={{
          pathname: `/`,
          state: { from: location },
        }}
      />
    );
  }

  async function newStamping(type) {
    const variables = { type };
    try {
      const result = await mutate({ variables });
      const { stamping } = result.data;
      switch (stamping.type) {
        case "START":
          history.push("/worker/dashboard");
          break;
        case "BREAK_START":
          history.push("/");
          break;
        case "BREAK_END":
          history.push("/worker/dashboard");
          break;
        case "END":
          history.push("/worker/timesheet");
          break;
        default:
          break;
      }
    } catch (error) {
      onError(error);
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Agile Factory - Badge</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          isBodyCentered={true}
          headHasBorderBottom={false}
          head={
            <>
              <Header hasSessionInfo={false} hasClock={false} hasHmiInfo>
                <div className="column has-text-right">
                  <Clock className="title is-1 is-double" />
                </div>
              </Header>
            </>
          }
          foot={
            <>
              <div className="columns">
                {(!lastStamping || ["END"].includes(lastStamping.type)) && (
                  <div
                    className={`column is-6 ${
                      !(
                        lastStamping &&
                        ["BREAK_START"].includes(lastStamping.type)
                      ) && "is-offset-3"
                    }`}
                  >
                    <Button
                      isFullWidth
                      theme={theme.buttons}
                      onClick={() => newStamping("START")}
                      data-cy="welcome-start"
                    >
                      <FormattedMessage
                        id="app.pages.welcome.button.start_day"
                        defaultMessage="Inizia giornata"
                      />
                    </Button>
                  </div>
                )}
                {lastStamping && ["START"].includes(lastStamping.type) && (
                  <div
                    className={`column is-6 ${
                      !(
                        lastStamping && ["START"].includes(lastStamping.type)
                      ) && "is-offset-3"
                    }`}
                  >
                    <Button
                      isFullWidth
                      theme={theme.buttons}
                      onClick={() => newStamping("BREAK_START")}
                      data-cy="welcome-start"
                    >
                      <FormattedMessage
                        id="app.pages.welcome.button.start_break"
                        defaultMessage="Inizia pausa"
                      />
                    </Button>
                  </div>
                )}
                {lastStamping &&
                  ["BREAK_START"].includes(lastStamping.type) && (
                    <div
                      className={`column is-6 ${
                        !(
                          lastStamping &&
                          ["START", "BREAK_END"].includes(lastStamping.type)
                        ) && "is-offset-3"
                      }`}
                    >
                      <Button
                        isFullWidth
                        theme={theme.buttons}
                        onClick={() => newStamping("BREAK_END")}
                        data-cy="welcome-start"
                      >
                        <FormattedMessage
                          id="app.pages.welcome.button.start_day"
                          defaultMessage="Fine pausa"
                        />
                      </Button>
                    </div>
                  )}
                {lastStamping &&
                  ["START", "BREAK_END"].includes(lastStamping.type) && (
                    <div
                      className={`column is-6 ${
                        !(
                          lastStamping && ["START"].includes(lastStamping.type)
                        ) && "is-offset-3"
                      }`}
                    >
                      <Button
                        isFullWidth
                        theme={theme.buttons}
                        onClick={() => newStamping("END")}
                        data-cy="welcome-start"
                      >
                        <FormattedMessage
                          id="app.pages.welcome.button.start_break"
                          defaultMessage="Fine giornata"
                        />
                      </Button>
                    </div>
                  )}
              </div>
            </>
          }
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div className="has-text-centered">
                <Avatar
                  size="12.8rem"
                  name={user.worker.name}
                  src={user.worker.image}
                />
                <div
                  className="title is-2 has-text-centered has-text-white"
                  style={{ marginTop: 10 }}
                >
                  {user.worker.name}
                </div>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default Badge;
