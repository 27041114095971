import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { FormattedMessage } from "react-intl";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout/Background";
import themes from "../../../libs/themes";
import ProductionBody from "../components/production/ProductionBody";
import Wrapper from "./components/Wrapper";

import { loader } from "graphql.macro";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";

const SETUP = loader("./Setup.query.graphql");

function Production() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();

  const variables = { sessionId };
  const { data, loading, error } = useQuery(SETUP, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { session } = data;
  console.log("SESSION", session);

  return (
    <Wrapper status="PRODUCTION">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* MENU */}
            <div className="column is-4">
              <Button
                isFullWidth
                onClick={() =>
                  history.push(`/application/menu/${phaseId}/${sessionId}`)
                }
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            <div className="column is-4 truncate">
              {session.hasSerialnumbers && (
                <Button
                  isFullWidth
                  theme={themes.productionBlue.buttons}
                  onClick={() =>
                    history.push(
                      `/application/scan-production/${phaseId}/${sessionId}`
                    )
                  }
                  data-cy="application-button-next"
                >
                  <FormattedMessage
                    id="app.pages.application.production.buttons.production_history"
                    defaultMessage="Scan production"
                  />
                </Button>
              )}
            </div>
            <div className="column is-4">
              <Button
                isFullWidth
                theme={themes.primary.buttons}
                onClick={() =>
                  history.push(`/application/check/${phaseId}/${sessionId}`)
                }
                data-cy="application-button-next"
              >
                <FormattedMessage
                  id="app.pages.application.production.buttons.check"
                  defaultMessage="Check"
                />
              </Button>
            </div>
          </div>
        }
      >
        <ProductionBody />
      </Background>
    </Wrapper>
  );
}

Production.defaultProps = {};
Production.propTypes = {};
export default Production;
