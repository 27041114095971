import React, { useState } from "react";
import PropTypes from "prop-types";
import Pagination from "react-paginating";

import Container from "./Container";
import Button from "../../../../../components/Button";

const PAGE_LIMIT = 8;

function ContainerList({ containers, onClick, session }) {
  const [currentPage, setCurrentPage] = useState(1);

  function paginate(containers, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return containers.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  }
  const total = containers.length;
  const pageCount = Math.ceil(total / PAGE_LIMIT);

  return (
    <Pagination
      total={total}
      limit={PAGE_LIMIT}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        getPageItemProps,
      }) => (
        <div className="columns is-vcentered">
          <div className="column is-narrow is-flex">
            {hasPreviousPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange: setCurrentPage,
                })}
              >
                <i
                  className="icon-arrow-left-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
          <div className="column">
            <div className="columns is-multiline">
              {paginate(containers, currentPage).map((container) => (
                <Container
                  key={container._id}
                  container={container}
                  onClick={() => onClick(container)}
                  session={session}
                />
              ))}
            </div>
          </div>
          <div className="column is-narrow is-flex">
            {hasNextPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange: setCurrentPage,
                })}
              >
                <i
                  className="icon-arrow-right-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
        </div>
      )}
    </Pagination>
  );
}

ContainerList.defaultProps = {
  containerId: null,
};
ContainerList.propTypes = {
  containerId: PropTypes.string,
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default ContainerList;
