import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Title from "../../../../components/Title";
import toast from "../../../../libs/toast";
import onError from "../../../../libs/error-logger";

import themes from "../../../../libs/themes";
import Background from "../../../../components/Layout";
import Header from "../../../../components/Header";
import { setLoading } from "../../../../actions";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import GenericError from "../../../errors/GenericError";
import ContainerUnloadStep1 from "./components/ContainerUnloadStep1";
import ContainerUnloadStep2 from "./components/ContainerUnloadStep2";
import { loader } from "graphql.macro";
const CONTAINER_UNLOAD = loader("./ContainerUnload.query.graphql");
const CONTAINER_UNLOAD_MUTATION = loader("./ContainerUnload.mutation.graphql");

function ContainerUnload() {
  const intl = useIntl();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [qty, setQty] = useState();
  const [notes, setNotes] = useState();
  const { containerId } = useParams();
  const dispatch = useDispatch();

  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_UNLOAD, { variables });
  const [mutate] = useMutation(CONTAINER_UNLOAD_MUTATION, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { qty, notes } });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { container } = data;
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Unload - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
        >
          {step === 1 && (
            <ContainerUnloadStep1
              container={container}
              setStep={setStep}
              setQty={setQty}
              qty={qty}
            />
          )}
          {step === 2 && (
            <ContainerUnloadStep2
              container={container}
              setStep={setStep}
              setNotes={setNotes}
              notes={notes}
              onSubmit={onSubmit}
            />
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerUnload.defaultProps = {};
ContainerUnload.propTypes = {};

export default ContainerUnload;
