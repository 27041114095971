import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";

const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.fg};
`;
const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.fg};
`;
const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.fg};
`;
const StyledH4 = styled.h4`
  color: ${({ theme }) => theme.fg};
`;
const StyledH5 = styled.h5`
  color: ${({ theme }) => theme.fg};
`;

function H1({ isSpaced, isEllipsed, children, ...props }) {
  return (
    <StyledH1
      className={classNames(
        "title is-1",
        isSpaced && "is-spaced",
        isEllipsed && "is-ellipsed"
      )}
      {...props}
    >
      {children}
    </StyledH1>
  );
}
function H2({ isSpaced, children, ...props }) {
  return (
    <StyledH2
      className={classNames("title is-2", isSpaced && "is-spaced")}
      {...props}
    >
      {children}
    </StyledH2>
  );
}
function H3({ isSpaced, children, ...props }) {
  return (
    <StyledH3
      className={classNames("title is-3", isSpaced && "is-spaced")}
      {...props}
    >
      {children}
    </StyledH3>
  );
}
function H4({ isSpaced, children, ...props }) {
  return (
    <StyledH4
      className={classNames("title is-4", isSpaced && "is-spaced")}
      {...props}
    >
      {children}
    </StyledH4>
  );
}
function H5({ isSpaced, children, ...props }) {
  return (
    <StyledH5
      className={classNames("title is-5", isSpaced && "is-spaced")}
      {...props}
    >
      {children}
    </StyledH5>
  );
}
const Title = { H1, H2, H3, H4, H5 };

const defaultProps = {};
const propTypes = {
  children: PropTypes.node,
  isSpaced: PropTypes.bool,
};
H1.defaultProps = defaultProps;
H2.defaultProps = defaultProps;
H3.defaultProps = defaultProps;
H4.defaultProps = defaultProps;
H5.defaultProps = defaultProps;
H1.propTypes = propTypes;
H2.propTypes = propTypes;
H3.propTypes = propTypes;
H4.propTypes = propTypes;
H5.propTypes = propTypes;
export default Title;
