import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import styled from "styled-components";

import {
  setContainerNotes,
  setContainerQty,
  setContainerScrap,
} from "../../../../actions";
import NumberField from "../../../../components/Fields/NumberField";
import TextareaField from "../../../../components/Fields/TextareaField";
import HmiFieldRoute from "../../../../components/HmiFieldRoute";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";

const CONTAINER_WITHDRAW = loader("../_shared/Withdraw.mutation.graphql");
const PICKING_ITEM = loader("../_shared/PickingItem.query.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

function WithdrawContainer() {
  const { containerId, sessionId, pickingId } = useParams();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const container = useSelector(({ container }) => container);

  const { data } = useQuery(PICKING_ITEM, {
    variables: { pickingId, sessionId },
  });

  const [mutateWithdraw] = useMutation(CONTAINER_WITHDRAW, {
    variables: { sessionId },
  });

  async function onSubmit() {
    try {
      const variables = {
        containerId: containerId,
        pickingId: pickingId,
        qty: container.qty,
        scrap: container.scrap,
        notes: container.notes,
      };
      console.log("variables: ", variables);

      await mutateWithdraw({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.go(-2);
    } catch (error) {
      onError(error);
    }
  }

  const item = data?.session.pickingItem.item;

  const subtitle = (
    <Badge>
      <i className="icon-product" /> {item?.code}
    </Badge>
  );

  return (
    <>
      {step === 0 && (
        <HmiFieldRoute
          title="Quante unità?"
          onNext={() => {
            setStep(1);
          }}
          onBack={() => {
            dispatch(setContainerQty());
            history.goBack();
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="qty"
              autofocus={true}
              placeholder={container.qty}
              defaultValue={container.qty}
              onChange={(value) => {
                dispatch(setContainerQty(value));
              }}
            />
          }
        />
      )}
      {step === 1 && (
        <HmiFieldRoute
          title="Quanti scarti?"
          onNext={() => {
            setStep(2);
          }}
          onBack={() => {
            dispatch(setContainerScrap());
            setStep(0);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="scrap"
              autofocus={true}
              placeholder={container.scrap}
              defaultValue={container.scrap}
              onChange={(value) => {
                dispatch(setContainerScrap(value));
              }}
            />
          }
        />
      )}
      {step === 2 && (
        <HmiFieldRoute
          title="Aggiungi una nota"
          onSubmit={onSubmit}
          onBack={() => {
            dispatch(setContainerNotes());
            setStep(1);
          }}
          subtitle2={subtitle}
          field={
            <TextareaField
              key="notes"
              autofocus={true}
              placeholder={container.notes}
              defaultValue={container.notes}
              onChange={(value) => {
                dispatch(setContainerNotes(value));
              }}
            />
          }
        />
      )}
    </>
  );
}

WithdrawContainer.defaultProps = {};
WithdrawContainer.propTypes = {};
export default WithdrawContainer;
