import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import styled from "styled-components";

import {
  setContainerBatch,
  setContainerNotes,
  setContainerQty,
  setContainerScrap,
  setContainerItem,
  resetContainerConfig,
} from "../../../../actions";
import NumberField from "../../../../components/Fields/NumberField";
import TextField from "../../../../components/Fields/TextField";
import TextareaField from "../../../../components/Fields/TextareaField";
import HmiFieldRoute from "../../../../components/HmiFieldRoute";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";
import SearchSelectField from "../../../../components/Fields/SearchSelectField";

const CONTAINER_WITHDRAW = loader("../_shared/Withdraw.mutation.graphql");
const BATCH_UPSERT = loader("../_shared/UpsertBatch.mutation.graphql");
const ITEM_UPSERT = loader("../_shared/UpsertItem.mutation.graphql");
const PICKING_ITEM_UPSERT = loader(
  "../_shared/UpsertPickingItem.mutation.graphql"
);
const ITEMS = loader("../_shared/Items.query.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

function WithdrawItem() {
  const { phaseId, sessionId } = useParams();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const container = useSelector(({ container }) => container);
  const [search, setSearch] = useState("");
  const variables = { options: { offset: 0, limit: 25 }, search, phaseId };
  const [itemName, setItemName] = useState();
  const isMountedRef = useRef(true);

  const { data } = useQuery(ITEMS, { variables });

  console.log("data:", data);

  const [mutateWithdraw] = useMutation(CONTAINER_WITHDRAW, {
    variables: { sessionId },
  });
  const [mutateUpsertBatch] = useMutation(BATCH_UPSERT, {
    variables: { sessionId },
  });

  const [mutateUpsertItem] = useMutation(ITEM_UPSERT, {
    variables: { sessionId },
  });

  const [mutateUpsertPickingItem] = useMutation(PICKING_ITEM_UPSERT);

  async function onSubmit() {
    try {
      const variables = {
        sessionId: sessionId,
        picking: {
          _item: container.item,
          qty: 0,
        },
      };

      const pickingItemResult = await mutateUpsertPickingItem({ variables });
      console.log("pickingItemResult:", pickingItemResult);
      const picking = pickingItemResult.data.session.picking.sort((p1, p2) =>
        p1._id > p2._id ? -1 : 1
      );
      const pickingId = picking[0]._id;

      toast({
        title: intl.formatMessage({ id: "app.shared.save_success" }),
      });

      try {
        const variables = {
          batch: {
            code: container.batch,
            _supplier: container.supplier,
            _item: container.item,
            qty: container.qty,
          },
        };

        const batchResult = await mutateUpsertBatch({ variables });
        const batchId = batchResult.data.batch._id;

        try {
          const variables = {
            pickingId: pickingId,
            qty: container.qty,
            scrap: container.scrap,
            notes: container.notes,
            batchId: batchId,
          };

          await mutateWithdraw({ variables });
          toast({
            title: intl.formatMessage({ id: "app.shared.save_success" }),
          });
          dispatch(resetContainerConfig());
          history.goBack();
        } catch (error) {
          onError(error);
        }
      } catch (error) {
        onError(error);
      }
    } catch (error) {
      onError(error);
    }
  }

  async function onSubmitItem() {
    try {
      const variables = { item: { code: itemName, name: itemName } };
      const itemResult = await mutateUpsertItem({ variables });
      const itemId = itemResult.data.item._id;
      dispatch(setContainerItem(itemId));
      if (isMountedRef.current) {
        setStep(2);
      }
    } catch (error) {
      onError(error);
    }
  }

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const itemsPaged = data?.itemsPaged;
  const phase = data?.phase;
  var items = itemsPaged?.items ?? [];
  //add first item for new item
  items = [
    {
      _id: "new",
      title: intl.formatMessage({ id: "app.shared.new" }),
      subtitle: intl.formatMessage({ id: "app.shared.item" }),
      image: "",
    },
    ...items,
  ];

  const subtitle = (
    <>
      <Badge>
        <i className="icon-sb-job-board-icn" />
        {phase?.job.name}
      </Badge>
      {phase?.station != null && (
        <Badge>
          <i className="icon-st-stations-icn" />
          {phase.station.name}
        </Badge>
      )}
    </>
  );

  return (
    <>
      {step === 0 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.batch.article",
          })}
          onNext={() => {
            setStep(2);
          }}
          onBack={() => {
            dispatch(resetContainerConfig());
            history.goBack();
          }}
          subtitle2={subtitle}
          field={
            <>
              <SearchSelectField
                key="item"
                autofocus={true}
                placeholder={container.item}
                defaultValue={container.item}
                options={items.map((i) => {
                  return {
                    value: i._id,
                    title:
                      i._id == "new"
                        ? intl.formatMessage({
                            id: "app.pages.application.withdraw_item.new",
                          })
                        : i.code + (i.name && "::" + i.name),
                    image: i.image ?? "",
                    subtitle: i.description,
                  };
                })}
                search={search}
                onSearch={setSearch}
                onChange={(value) => {
                  console.log("value:", value);
                  if (value == "new") {
                    setStep(1);
                  } else {
                    dispatch(setContainerItem(value));
                  }
                }}
              />
            </>
          }
        />
      )}
      {step === 1 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.withdraw_item.item.code",
          })}
          onNext={() => {
            onSubmitItem();
          }}
          onBack={() => {
            dispatch(setContainerBatch());
            setStep(0);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="batch"
              autofocus={true}
              placeholder={container.batch}
              defaultValue={container.batch}
              onChange={(value) => {
                setItemName(value);
              }}
            />
          }
        />
      )}
      {step === 2 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.produced.title",
          })}
          onNext={() => {
            setStep(3);
          }}
          onBack={() => {
            dispatch(setContainerQty());
            setStep(0);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="qty"
              autofocus={true}
              placeholder={container.qty}
              defaultValue={container.qty}
              onChange={(value) => {
                dispatch(setContainerQty(value));
              }}
            />
          }
        />
      )}
      {step === 3 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.scrap.title",
          })}
          onNext={() => {
            setStep(4);
          }}
          onBack={() => {
            dispatch(setContainerScrap());
            setStep(2);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="scrap"
              autofocus={true}
              placeholder={container.scrap}
              defaultValue={container.scrap}
              onChange={(value) => {
                dispatch(setContainerScrap(value));
              }}
            />
          }
        />
      )}
      {step === 4 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.title",
          })}
          onNext={() => {
            setStep(5);
          }}
          onBack={() => {
            dispatch(setContainerBatch());
            setStep(3);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="batch"
              autofocus={true}
              placeholder={container.batch}
              defaultValue={container.batch}
              onChange={(value) => {
                dispatch(setContainerBatch(value));
              }}
            />
          }
        />
      )}
      {step === 5 && (
        <HmiFieldRoute
          title="Aggiungi una nota"
          onSubmit={onSubmit}
          onBack={() => {
            dispatch(setContainerNotes());
            setStep(4);
          }}
          subtitle2={subtitle}
          field={
            <TextareaField
              key="notes"
              autofocus={true}
              placeholder={container.notes}
              defaultValue={container.notes}
              onChange={(value) => {
                dispatch(setContainerNotes(value));
              }}
            />
          }
        />
      )}
    </>
  );
}

WithdrawItem.defaultProps = {};
WithdrawItem.propTypes = {};
export default WithdrawItem;
