import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import BackImage from "../../../../images/arrow-back-gray-icn.svg";
import { useDispatch, useSelector } from "react-redux";
import { setStation } from "../../../../actions";
import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import themes from "../../../../libs/themes";

function AddOperations() {
  const history = useHistory();
  const theme = themes.default;
  const dispatch = useDispatch();
  const { flow } = useSelector(({ config }) => config);

  function openPage(page) {
    dispatch(setStation(null));
    history.push(`/configuration/${page}`);
  }

  function onBack() {
    switch (flow) {
      case "DASHBOARD":
        return history.push("/worker/dashboard");
      case "MANUAL_SESSION":
        return history.push("/worker/timesheet");
      default:
        return history.push("/worker/dashboard");
    }
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Menu - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={themes.neutralGrey}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth theme={theme.buttons} onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
              <div className="column is-4 is-offset-3"></div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.welcome.button.operations"
                    defaultMessage="Add operations"
                  />
                </h1>

                <p className="subtitle is-6 fg-secondary">
                  <FormattedMessage
                    id="app.pages.welcome.button.operations.subtitle"
                    defaultMessage="Add operations to your session from stations or other operations"
                  />
                  <br />
                </p>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column"></div>
              <div className="column is-6 has-text-centered">
                <div className="column">
                  <Button
                    isFullWidth
                    onClick={() => openPage("station")}
                    data-cy="application-Menu-material"
                  >
                    <FormattedMessage
                      id="app.pages.welcome.button.station"
                      defaultMessage="Select station"
                    />
                  </Button>
                </div>
                <div className="column">
                  <Button
                    isFullWidth
                    onClick={() => openPage("order")}
                    data-cy="application-Menu-setup"
                  >
                    <FormattedMessage
                      id="app.pages.welcome.button.order"
                      defaultMessage="Select order"
                    />
                  </Button>
                </div>
                <div className="column">
                  <Button
                    isFullWidth
                    onClick={() => openPage("operation")}
                    data-cy="application-Menu-setup"
                  >
                    <FormattedMessage
                      id="app.pages.welcome.button.operation"
                      defaultMessage="Select operator"
                    />
                  </Button>
                </div>
              </div>
              <div className="column"></div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

AddOperations.defaultProps = {};
AddOperations.propTypes = {};

export default AddOperations;
