import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Columns = styled.div`
  border-bottom: 0.3px solid #efefef;
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
`;

function MachineParameters({ parameters }) {
  return (
    <>
      {/* LISTENING */}
      <Columns className="columns">
        <div className="column">
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.listening"
                defaultMessage="Listening"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.LISTENING ? (
                <small className="has-text-success">
                  <FormattedMessage
                    id="app.pages.application.machine.settings.listening"
                    defaultMessage="Communication available"
                  />{" "}
                  <span role="img" aria-label="Listening">
                    📡
                  </span>
                </small>
              ) : (
                <small className="has-text-danger">
                  <FormattedMessage
                    id="app.pages.application.machine.settings.not_listening"
                    defaultMessage="Communication not available"
                  />{" "}
                  <span role="img" aria-label="Not listening">
                    🚫
                  </span>
                </small>
              )}
            </div>
          </h4>
        </div>
        {/* STATUS */}

        <div className="column">
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.status"
                defaultMessage="Status"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.STATUS}
            </div>
          </h4>
        </div>
      </Columns>
      <Columns className="columns">
        <div className="column">
          {/* ERROR */}
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.error"
                defaultMessage="Error"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.ERROR ? (
                <span role="img" aria-label="error">
                  ⚠️
                </span>
              ) : (
                "-"
              )}
            </div>
          </h4>
        </div>
        <div className="column">
          {/* ORDER */}
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.order"
                defaultMessage="Order"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.ORDER || "-"}
            </div>
          </h4>
        </div>
      </Columns>
      <Columns className="columns">
        <div className="column">
          {/* PROGRAM */}
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.program"
                defaultMessage="Program"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.PROGRAM || "-"}
            </div>
          </h4>
        </div>
        <div className="column">
          {/* CYCLES */}
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.cycles"
                defaultMessage="Pieces"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.CYCLES || "-"}
            </div>
          </h4>
        </div>
      </Columns>
      <Columns className="columns">
        <div className="column">
          {/* QUANTITY */}
          <h4 className="title is-4 has-text-weight-normal">
            <span className="has-text-grey">
              <FormattedMessage
                id="app.pages.application.machine.settings.parameter.qty"
                defaultMessage="Quantity"
              />
            </span>{" "}
            <div className="title is-3 has-text-weight-bold">
              {parameters.QTY || "-"}
            </div>
          </h4>
        </div>
      </Columns>
    </>
  );
}

MachineParameters.defaultProps = {};
MachineParameters.propTypes = {
  parameters: PropTypes.shape({
    PROGRAM: PropTypes.string,
    STATUS: PropTypes.string,
    ORDER: PropTypes.string,
    ERROR: PropTypes.bool,
    LISTENING: PropTypes.bool,
    QTY: PropTypes.number,
    CYCLES: PropTypes.number,
  }).isRequired,
};
export default MachineParameters;
