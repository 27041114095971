import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import NumberField from "../../../../../components/Fields/NumberField";
import HmiField from "../../../../../components/HmiField/HmiField";
import toast from "../../../../../libs/toast";

function ContainerLoadStep1({ qty, setQty, container, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  function onNext() {
    if (isNaN(qty)) {
      toast({
        title: intl.formatMessage({ id: "app.shared.nan" }),
        icon: "error",
      });
      return;
    }
    if (Number(qty) <= 0) {
      toast({
        title: intl.formatMessage({ id: "app.shared.gt_zero" }),
        icon: "error",
      });
      return;
    }
    if (container.capacity && qty + container.current > container.capacity) {
      toast({
        title: intl.formatMessage({
          id: "app.pages.application.container_load.qty.error.too_much",
          defaultMessage: "Sorry, total quantity will be more than capacity",
        }),
        icon: "error",
      });
      return;
    }
    setStep(2);
  }
  return (
    <HmiField
      title={intl.formatMessage({
        id: "app.pages.application.container_load.qty.title",
        defaultMessage: "How many units?",
      })}
      subtitle={container.name}
      subtitle2={
        container.item && `${container.item.code} :: ${container.item.name}`
      }
      field={
        <NumberField
          key="qty"
          placeholder={qty || 0}
          defaultValue={qty || 0}
          onChange={(value) => {
            setQty(+value);
          }}
        />
      }
      value={qty || 0}
      onBack={() => history.goBack()}
      onNext={onNext}
    />
  );
}

ContainerLoadStep1.defaultProps = {
  qty: null,
};
ContainerLoadStep1.propTypes = {
  qty: PropTypes.number,
  setQty: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
    capacity: PropTypes.number,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerLoadStep1;
