import classNames from "classnames";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { ThemeProvider } from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import { setError, setStatus } from "../../../actions";
import { MediumButton } from "../../../components/Button";
import TextareaField from "../../../components/Fields/TextareaField";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import StepHeader from "../../../components/StepHeader";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import { withPhase } from "../../../hocs";
import themes, { buttons } from "../../../libs/themes";

function ErrorReport({ intl }) {
  const { sessionId, phaseId } = useParams();
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const delivery = useSelector(({ delivery }) => ({
    message: delivery.error,
  }));
  const dispatch = useDispatch();
  const theme = themes.errorRed;

  function onSubmit() {
    if (!message || message.length === 0) {
      setIsError(true);
      setTimeout(() => setIsError(false), 800);
      return;
    }
    dispatch(setError(message));
    dispatch(setStatus("ERROR"));
    history.push(`/application/produced/${phaseId}/${sessionId}`);
  }

  function onChange(value) {
    setMessage(value);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Problem Report - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.errorRed.buttons}>
                  <FormattedMessage
                    id="app.shared.error"
                    defaultMessage="Fault"
                  />
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.error_report.title"
                    defaultMessage="Report fault"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={() => history.goBack()}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div
                className={classNames(
                  "column has-text-centered",
                  isError && "animated shake duration400"
                )}
              >
                <div className="separator" />
                <TextareaField
                  key="problem-issue"
                  cy="application-issue-input"
                  autofocus={true}
                  placeholder={intl.formatMessage({
                    id: "app.pages.application.error_report.placeholder",
                  })}
                  defaultValue={message ?? delivery.message ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default compose(injectIntl, withPhase())(ErrorReport);
