import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Session from "./Session";
import Loader from "../../Loader";
import Avatar from "../../Avatar";
import { statuses } from "../../../libs/themes";

const StatusCircle = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: 0.3rem solid rgba(245, 245, 245, 0.5);
  transition: background-color 250ms ease-in-out,
    border-color 250ms ease-in-out 250ms;
  color: ${({ theme }) => theme?.fg ?? "black"};
  background-color: ${({ theme }) => theme?.border ?? "white"};
  &.neutral {
    color: black !important;
    background-color: white !important;
    border: 0.3rem solid rgba(220, 220, 220, 1);
  }
`;

function SessionsList({
  subscribeToUpdates,
  loading,
  data,
  error,
  onSessionClick,
  onWorkerClick,
  isDisabled,
}) {
  const { sessionId } = useParams();
  useEffect(subscribeToUpdates, []);

  if (loading && !data) {
    return (
      <Session isDisabled>
        <span style={{ fontSize: "3.4rem" }}>
          <Loader message={null} backgroundColor="white" />
        </span>
      </Session>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { sessions = [] } = data;

  var sessionsGrouped = [];
  sessions.forEach((session) => {
    if (
      sessionsGrouped.findIndex((g) => {
        return g.worker._id == session.worker._id;
      }) == -1
    ) {
      sessionsGrouped.push(session);
    }
  });

  sessionsGrouped = sessions.reduce((group, arr) => {
    const { _id } = arr.worker;
    group[_id] = group[_id] ?? [];
    group[_id].push(arr);
    return group;
  }, {});

  const workerIds = Object.keys(sessionsGrouped);

  return (
    <>
      {workerIds.map((workerId) => {
        const group = sessionsGrouped[workerId];
        const session = sessionsGrouped[workerId][0];
        const isMultiple = group.length > 1;
        return (
          <Session
            key={session._id}
            isActive={sessionId === session._id}
            isDisabled={
              sessionId === session._id ||
              ["INTERRUPT", "DONE"].includes(session.status) ||
              isDisabled
            }
            session={session}
            onClick={() =>
              isMultiple ? onWorkerClick(session) : onSessionClick(session)
            }
            // onLogout={onLogout}
            data-cy="components-sessions-session"
            data-cy-id={session._id}
          >
            <figure className="image is-48x48">
              <Avatar
                size="4.8rem"
                color="transparent"
                fgColor={sessionId === session._id ? "#363636" : "white"}
                name={session.worker.name}
                src={session.worker.image}
              />
            </figure>
            {!isMultiple && (
              <StatusCircle
                theme={group.length == 1 && statuses[session.status]}
                className={group.length > 1 && "neutral"}
              />
            )}
          </Session>
        );
      })}
    </>
  );
}

SessionsList.defaultProps = {};
SessionsList.propTypes = {};
export default SessionsList;
