import React from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";

const SIZE = 24;

const Circle = styled.div`
  background-color: ${({ theme }) => theme.circle || "#fff"};
  min-width: 2.8rem;
  height: 2.8rem;
  border-radius: 1.4rem;
  display: inline-block;
  margin-right: 0.4rem;
  padding: 0.4rem 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
  & svg {
    fill: #fff;
  }
`;

function CircleElem({
  children,
  selectable = false,
  selected = false,
  withCircle = true,
  onSelect,
  theme,
  icon,
  ...props
}) {
  return (
    <>
      <Circle theme={theme}>
        {icon && <img src={icon} alt="" width={SIZE} height={SIZE} />}
        {children}
      </Circle>
    </>
  );
}

CircleElem.defaultProps = {};
CircleElem.propTypes = {};
export default compose(withTheme)(CircleElem);
