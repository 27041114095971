import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import styled from "styled-components";

const KeyboardContainer = styled.div`
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  .hg-rows {
    margin: auto;
    width: calc(100% - 48px);
    max-width: 1840px;
  }
  .hg-button {
    box-sizing: border-box;
    padding: 42px auto;
    height: calc(100vw / 14 / 1.618) !important;
    max-height: calc(25vh - 90px) !important;
    font-size: calc(14px + 1.1vw);
  }
  .hg-button-bksp {
    font-family: "agile-factory";
    width: 6%;
  }
`;

const PadContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 1;
  .hg-rows {
    margin: auto;
    width: 64.4rem;
    max-width: 100%;
  }
  .hg-button {
    flex-basis: 33.3% !important;
    width: 33%;
    height: calc(100vw / 12 / 1.618) !important;
    max-height: calc(25vh - 110px) !important;
    padding: 24px 72px !important;
    font-size: calc(12px + 1.1vw);
  }
  .hg-button-bksp {
    font-family: "agile-factory";
  }
`;

function OnScreenKeyboard({ inputNode, layouts = ["latin"] }) {
  const [value, setValue] = React.useState("");
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD } = hmiConfiguration;
  if (!ON_SCREEN_KEYBOARD) {
    return <span />;
  }
  if (!inputNode) {
    return <span />;
  }

  function onChange(input, e) {
    e.preventDefault();
    setValue(input);
    inputNode.value = input;
    let event = new Event("input", { bubbles: true });
    inputNode.dispatchEvent(event);
  }

  if (layouts[0] === "numeric") {
    return (
      <PadContainer>
        <Keyboard
          preventMouseDownDefault={true}
          onChange={onChange}
          value={value}
          layout={{
            default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
          }}
          display={{
            "{bksp}": "&#xe85f",
          }}
        />
      </PadContainer>
    );
  }
  return (
    <KeyboardContainer
      onClick={(e) => e.stopPropagation() && e.preventDefault()}
    >
      <Keyboard
        preventMouseDownDefault={true}
        onChange={onChange}
        value={value}
        display={{
          "{bksp}": "&#xe85f",
        }}
      />
    </KeyboardContainer>
  );
}

OnScreenKeyboard.defaultProps = {
  inputNode: null,
};
OnScreenKeyboard.propTypes = {
  inputNode: PropTypes.object,
  layouts: PropTypes.arrayOf(PropTypes.any),
  rightButtons: PropTypes.arrayOf(PropTypes.any),
  leftButtons: PropTypes.arrayOf(PropTypes.any),
};
export default OnScreenKeyboard;
