import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Title from "../../../../components/Title";
import toast from "../../../../libs/toast";
import onError from "../../../../libs/error-logger";

import themes from "../../../../libs/themes";
import Background from "../../../../components/Layout";
import Header from "../../../../components/Header";
import { setLoading } from "../../../../actions";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import GenericError from "../../../errors/GenericError";
import ContainerLoadStep1 from "./components/ContainerLoadStep1";
import ContainerLoadStep2 from "./components/ContainerLoadStep2";
import ContainerLoadStep3 from "./components/ContainerLoadStep3";
import ContainerLoadStep4 from "./components/ContainerLoadStep4";
import ContainerLoadStep5 from "./components/ContainerLoadStep5";
import { loader } from "graphql.macro";
const CONTAINER_LOAD = loader("./ContainerLoad.query.graphql");
const CONTAINER_LOAD_MUTATION = loader("./ContainerLoad.mutation.graphql");
const UPSERT_BATCH_MUTATION = loader("./UpsertBatch.mutation.graphql");

function ContainerLoad() {
  const intl = useIntl();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [qty, setQty] = useState();
  const [scrap, setScrap] = useState();
  const [notes, setNotes] = useState();
  const [batch, setBatch] = useState();
  const [supplier, setSupplier] = useState();

  const { containerId } = useParams();
  const dispatch = useDispatch();

  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_LOAD, { variables });
  const [mutateLoadContainer] = useMutation(CONTAINER_LOAD_MUTATION, {
    variables,
  });
  const [mutateUpsertBatch] = useMutation(UPSERT_BATCH_MUTATION, { variables });

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      var batchResult = await mutateUpsertBatch({
        variables: {
          batch: {
            code: batch,
            _supplier: supplier != "" ? supplier : null,
            qty: Number(qty),
            _item: container._item,
          },
        },
      });
      if (!batchResult.data.batch) {
        throw new Error(
          intl.formatMessage({
            id: "app.pages.stock.containers.container.details.actions.load.batch.error",
            defaultMessage: "Sorry, batch not created",
          })
        );
      }
      var batchId = batchResult.data.batch._id;
      await mutateLoadContainer({
        variables: {
          containerId,
          qty: Number(qty),
          scrap: Number(scrap),
          batchId,
          notes,
        },
      });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { container, suppliers } = data;
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Load - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
        >
          <>
            {step === 1 && (
              <ContainerLoadStep1
                container={container}
                setStep={setStep}
                setQty={setQty}
                qty={qty}
              />
            )}

            {step === 2 && (
              <ContainerLoadStep2
                container={container}
                setStep={setStep}
                setScrap={setScrap}
                scrap={scrap}
              />
            )}

            {step === 3 && (
              <ContainerLoadStep3
                container={container}
                setStep={setStep}
                setBatch={setBatch}
                batch={batch}
              />
            )}

            {step === 4 && (
              <ContainerLoadStep4
                container={container}
                setStep={setStep}
                setSupplier={setSupplier}
                supplier={supplier}
                suppliers={suppliers}
              />
            )}

            {step === 5 && (
              <ContainerLoadStep5
                container={container}
                setStep={setStep}
                setNotes={setNotes}
                notes={notes}
                onSubmit={onSubmit}
              />
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerLoad.defaultProps = {};
ContainerLoad.propTypes = {};

export default ContainerLoad;
