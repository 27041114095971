import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import confirmAction from "../../../../libs/confirm_action";
import Button from "../../../../components/Button";

function ScanWorkId({ onSubmit }) {
  const intl = useIntl();

  async function onClick() {
    await confirmAction({
      confirm: (code) => onSubmit({ code }),
      options: {
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          autocomplete: "off",
        },
        showCancelButton: true,
        title: intl.formatMessage({
          id: "app.pages.configuration.operators.barcode.title",
          defaultMessage: "Scan your barcode",
        }),
        text: intl.formatMessage({
          id: "app.pages.configuration.operators.barcode.text",
          defaultMessage: "Use your barcode to login",
        }),
      },
    });
  }

  return (
    <Button
      isFullWidth
      onClick={onClick}
      data-cy="configuration-operators-scan_work_id"
    >
      <FormattedMessage
        id="app.pages.configuration.operators.barcode"
        defaultMessage="Scan work id"
      />
    </Button>
  );
}

ScanWorkId.defaultProps = {};
ScanWorkId.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default ScanWorkId;
