import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Background from "../../../components/Layout";
import themes from "../../../libs/themes";
import Header from "../../../components/Header";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { loader } from "graphql.macro";
const NEXT_PHASE = loader("./NextPhase.query.graphql");

function NextPhase() {
  const location = useLocation();
  const history = useHistory();

  const { stationId } = useSelector(({ config }) => config);
  const variables = { stationId };
  const { loading, error, data, refetch } = useQuery(NEXT_PHASE, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { phase } = data;
  if (phase) {
    return (
      <Redirect
        to={{
          pathname: `/configuration/definition/${phase.job._id}/${phase._id}`,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operations - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={themes.neutralGrey}>
        <Background
          head={<Header hasSessionInfo={false} hasUserInfo />}
          foot={
            <div className="columns">
              <div className="column">
                <Button isFullWidth onClick={() => history.push("/")}>
                  <FormattedMessage
                    id="app.shared.signout"
                    defaultMessage="Sign out"
                  />
                </Button>
              </div>
              <div className="column">
                <Button isFullWidth onClick={() => refetch()}>
                  <FormattedMessage
                    id="app.shared.reload"
                    defaultMessage="Reload"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.configuration.operations.title"
                    defaultMessage="Choose phase"
                  />
                </h1>
              </div>
            </div>
            {/* DATA */}
            <div className="columns">
              <div className="column is-10 is-offset-1 has-text-centered">
                <Title.H2>
                  <FormattedMessage
                    id="app.pages.configuration.operations.no_phase"
                    defaultMessage="There is no phase, contact responsible"
                  />
                </Title.H2>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

NextPhase.defaultProps = {};
NextPhase.propTypes = {};
export default NextPhase;
