import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { darken } from "polished";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import themes from "../../libs/themes";
import nativeChannel from "../../nativeChannel";
import Button from "../Button";
import ListElement from "../ListElement";
import SearchField from "./SearchField";

const selectedTheme = {
  ...themes.default.listElement,
  bg: darken(0.05, themes.default.listElement.bg),
};

function SearchSelectField({
  placeholder,
  onChange,
  search,
  onSearch,
  defaultValue,
  options,
  isError = false,
  isScannable = false,
  autofocus = false,
}) {
  const inputEl = useRef(null);
  const intl = useIntl();
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { BARCODE_SCANNER } = hmiConfiguration;

  useEffect(() => {
    if (autofocus) {
      inputEl && inputEl.current && inputEl.current.select();
    }
    if (isScannable) {
      window.onBarcode = onBarcode;
      nativeChannel.on("barcode", onBarcode);
      return () => {
        window.onBarcode = null;
        nativeChannel.off("barcode", onBarcode);
      };
    }
  }, [inputEl]);
  function onBarcodeSearch() {
    nativeChannel.send("read_barcode");
  }

  async function onBarcode(barcode) {
    if (options.find((option) => option.value == barcode) != null) {
      onSearch(barcode);
    }
  }

  return (
    <>
      <div className="columns">
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <div className="columns is-fullwidth">
            <div className="column">
              <SearchField
                placeholder={intl.formatMessage({
                  id: "app.pages.application.container_item.search.placeholder",
                  defaultMessage: "Search item...",
                })}
                defaultValue={search}
                onChange={onSearch}
              />
            </div>
          </div>
          {BARCODE_SCANNER && isScannable && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={onBarcodeSearch}
              data-cy="application-button-scan"
              style={{
                position: "absolute",
                right: 0,
                width: "27rem",
                height: "7.5rem",
                transform: "translate(-48px, 8px)",
              }}
            >
              <i className="icon-barcode" style={{ fontSize: "2.4rem" }} />
              <p className="title is-4">Scan barcode</p>
            </Button>
          )}
        </div>
      </div>
      <div className="columns is-fullwidth">
        <div className="column">
          {options.map((option, index) => (
            <ListElement
              key={option.value}
              onClick={() => onChange(option.value)}
              theme={
                option.value == "new"
                  ? themes.neutralGrey.listElement
                  : option.value == defaultValue
                  ? selectedTheme
                  : themes.default.listElement
              }
              data-cy="application-container_item-item"
              data-cy-id={option.value}
              className={option.value == defaultValue ? "selected" : ""}
              icon={option.image}
              withCircle={option.image != null}
              isNew={option.value == "new"}
            >
              <div className="columns is-vcentered is-fullwidth is-marginless">
                <div className="column is-narrow">
                  {option.value == "new" ? (
                    <p className="title is-4"> {option.title} </p>
                  ) : (
                    <ul>
                      <li className="title is-4">{option.title}</li>
                      <li className="subtitle is-4 fg-secondary is-marginless-bottom">
                        {option.subtitle}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </ListElement>
          ))}
        </div>
      </div>
    </>
  );
}

SearchSelectField.defaultProps = {};
SearchSelectField.propTypes = {};

export default SearchSelectField;
