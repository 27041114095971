import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { loader } from "graphql.macro";
import styled from "styled-components";
import NumberFormatter from "../libs/format-number";
import themes from "../libs/themes";
import LoadingIndicator from "./LoadingIndicator";
import SessionBadge from "./SessionBadge";
import Subtitle from "./Subtitle";
import Unit from "./Unit"
const STEP_HEADER = loader("./StepHeader.query.graphql");

/*
const Badge = styled.div`
  display: inline-block;
  margin-right: 0.4rem;

  &::after{
    color: #ddd;
    content: " •";
  }
  &:last-child{
    &::after{
      content: "";
    }
  }
`;*/

const Badge = styled.span`
  display: inline-block;
  margin-right: 0.8rem;
  margin-left: -0.2rem;
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
`;

function StepHeader({ type }) {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(STEP_HEADER, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;

  return (
    <>
      <Subtitle.S3 theme={themes.secondary} style={{ marginBottom: ".5rem" }}>
        {/* NAME */}
        {type === "INPUT" && (
          <strong>{`${phase.sort} :: ${phase.name}`}</strong>
        )}
      </Subtitle.S3>
      <Subtitle.S4 theme={themes.secondary}>
        {/* PRODUCT */}
        {phase.product && (
          <>
            <i className="icon-product" /> {phase.product.code} ::{" "}
            {phase.product.name} <br />
          </>
        )}

        {/* JOB */}
        <Badge>
          <i className="icon-sb-job-board-icn" />
          {phase.job.name}
        </Badge>

        {phase.job.customer && (
          <Badge>
            <i className="icon-st-users-icn" />
            {phase.job.customer.name}
          </Badge>
        )}

        {phase.activeSessions.length > 0 && (
          <span
            onClick={() => {
              return history.push(
                `/application/sessions/${phaseId}/${sessionId}`
              );
            }}
          >
            <SessionBadge isSmall={false} sessions={phase.activeSessions} />
          </span>
        )}

        {phase.station != null && (
          <Badge>
            <i className="icon-st-stations-icn" />
            {phase.station.name}
          </Badge>
        )}
        {type !== "PRODUCTION" && (
          <Badge>
            <Unit unit={phase.unit} />
            <NumberFormatter value={phase.produced} />
            {" / "}
            <NumberFormatter value={phase.qty} />
          </Badge>
        )}
        <Badge>
          <i className="icon-trash" />
          <NumberFormatter value={phase.scrap} />
        </Badge>

        {/* PRODUCTIVITY */}
      </Subtitle.S4>
    </>
  );
}

StepHeader.defaultProps = {
  type: "STEP",
};
StepHeader.propTypes = {
  type: PropTypes.oneOf(["STEP", "INPUT", "PRODUCTION"]),
};
export default StepHeader;
