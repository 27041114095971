import React from "react";
import PropTypes from "prop-types";
import config from "../config/config";

function Version({ color }) {
  return (
    <div
      style={{
        position: "absolute",
        right: "2.2rem",
        bottom: ".1rem",
        color,
        fontSize: "1.2rem",
      }}
    >
      v{config.version} ({config.build})
    </div>
  );
}

Version.defaultProps = {
  color: "white",
};
Version.propTypes = {
  color: PropTypes.string,
};
export default Version;
