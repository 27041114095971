import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";

import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../../libs/themes";
import ContainerList from "./components/ContainerList";
import onError from "../../../../libs/error-logger";
import SearchField from "../../../../components/Fields/SearchField";
import confirmAction from "../../../../libs/confirm_action";

const CONTAINERS = loader("./ContainerChoose.query.graphql");
const CHOOSE_CONTAINER = loader("./ContainerChoose.mutation.graphql");

function ContainerChoose() {
  const intl = useIntl();
  const { phaseId, sessionId, type, itemId } = useParams();
  const history = useHistory();
  const { stationId } = useSelector(({ config }) => config);

  const [mutate] = useMutation(CHOOSE_CONTAINER);

  const variables = { phaseId, sessionId, stationId };

  if (type === "add") {
    variables.where = { _station: { $ne: stationId } };
  }

  const { data, loading, error } = useQuery(CONTAINERS, { variables });

  const [filter, setFilter] = useState("");

  function filterer(container) {
    if (filter && filter.length === 0) {
      return true;
    }
    if (
      container &&
      container.name.toLowerCase().includes(filter.toLowerCase())
    ) {
      return true;
    }
    if (
      container &&
      container.code.toLowerCase().includes(filter.toLowerCase())
    ) {
      return true;
    }

    return false;
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { containers, session } = data;

  // Containers without item
  let nullFiltered = containers?.filter((c) => !c._item || !itemId) ?? [];

  // Containers with item selected
  let itemFiltered = [];
  if (itemId != null) {
    itemFiltered = containers?.filter((c) => c._item == itemId) ?? [];
  }
  // Containers with item not selected
  let notItemFiltered = [];
  if (itemId != null) {
    notItemFiltered =
      containers?.filter((c) => c._item != itemId && c._item != null) ?? [];
  }

  var groupedContainers = [
    ...itemFiltered,
    ...notItemFiltered,
    ...nullFiltered,
  ];

  const filtered = groupedContainers.filter(filterer);

  async function onContainerClick(container) {
    checkItem(container, () => chooseContainer(container._id, null));
  }

  async function checkItem(container, callback) {
    if (itemId != null) {
      if (container && itemId !== container._item) {
        return confirmAction({
          confirm: async () => {
            callback();
          },
          options: {
            text: intl.formatMessage({
              id: "app.pages.application.supply_item_container.warning.item_different",
              defaultMessage: "Warning, container item is different",
            }),
          },
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
  async function chooseContainer(containerId, code) {
    try {
      await mutate({
        variables: {
          containerId,
          code,
          phaseId,
          sessionId,
          type,
        },
      });
      return history.goBack();
    } catch (e) {
      onError(e);
    }
  }

  const theme = themes.neutralGrey;

  return (
    <>
      <Helmet>
        <title>Choose container - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasSessionInfo={false} hasUserInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt="Back"
                  />
                </Button>
              </div>
              <div className="column is-7"></div>

              <div className="column is-4">
                {((type === "scrap" && session._containerScrap != null) ||
                  (type === "output" && session._containerOutput != null)) && (
                  <Button
                    isFullWidth
                    theme={themes.errorRed.buttons}
                    onClick={() => chooseContainer(null, null)}
                  >
                    <p className="title is-3 fg-white">
                      <FormattedMessage
                        id="app.shared.unset"
                        defaultMessage="Unset"
                      />
                    </p>
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column" style={{ paddingLeft: "3.2rem" }}>
                <Title.H1 className="title is-2">
                  <FormattedMessage
                    id="app.pages.application.containers.add.title"
                    defaultMessage="Add containers"
                  />
                </Title.H1>
                <Subtitle.S3 className="subtitle is-4" theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.containers.subtitle"
                    defaultMessage="Configure container"
                  />
                </Subtitle.S3>
              </div>
              <div
                className="column"
                style={{ position: "relative", paddingRight: "2.8rem" }}
              >
                <SearchField
                  autofocus={false}
                  placeholder={intl.formatMessage({
                    id: "app.pages.application.containers.search.placeholder",
                    defaultMessage: "Search container...",
                  })}
                  defaultValue={filter}
                  onChange={(value) => setFilter(value)}
                />
              </div>{" "}
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <ContainerList
                  containers={filtered}
                  onClick={(container) => {
                    onContainerClick(container);
                  }}
                  session={session}
                />
              </div>
              {containers.length === 0 && (
                <div className="column">
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.application.containers.empty"
                      defaultMessage="Containers not configured, please contact a manager"
                    />
                  </Title.H2>
                </div>
              )}
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerChoose.defaultProps = {};
ContainerChoose.propTypes = {};
export default ContainerChoose;
