import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useQuery, useSubscription } from "@apollo/client";

import Title from "../../../components/Title";
import Button from "../../../components/Button";
import themes, { buttons } from "../../../libs/themes";
import Background from "../../../components/Layout";
import Tag, { SmallTag } from "../../../components/Tag";
import Header from "../../../components/Header";
import SetStatus from "../../../components/SetStatus";
import StepHeader from "../../../components/StepHeader";
import Timer from "../../../components/Timer";
import MachineParameters from "./components/MachineParameters";

import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { loader } from "graphql.macro";
const MACHINE_QUERY = loader("./Machine.query.graphql");
const MACHINE_SUBSCRIPTION = loader("./Machine.subscription.graphql");

function Machine() {
  const { phaseId, sessionId, machineId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId, machineId };
  const { data, loading, error } = useQuery(MACHINE_QUERY, { variables });
  useSubscription(MACHINE_SUBSCRIPTION, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  const { machine } = session;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configure machine - Agile Factory</title>
      </Helmet>
      <SetStatus status="SETUP">
        <ThemeProvider theme={themes.setupYellow}>
          <Background
            head={<Header hasPhaseInfo />}
            foot={
              <div className="columns">
                <div className="column is-4">
                  <Button
                    isFullWidth
                    onClick={() => history.goBack()}
                    data-cy="application-button-back"
                  >
                    <i className="icon-arrow-left-small-icn" />{" "}
                    <FormattedMessage
                      id="app.shared.back"
                      defaultMessage="back"
                    />
                  </Button>
                </div>
              </div>
            }
          >
            <>
              <div className="columns">
                <div className="column">
                  <SmallTag isUpperCase isLight theme={buttons.setupYellow}>
                    <FormattedMessage
                      id="app.shared.setup"
                      defaultMessage="Setup"
                    />
                  </SmallTag>
                  <Title.H1>
                    {phase.sort} :: {phase.name}
                  </Title.H1>
                  <StepHeader />
                </div>
                <div className="column is-narrow">
                  <Tag theme={buttons.setupYellow}>
                    <i className="icon-giphy-time" />
                    <Timer time={phase?.session?.timers?.SETUP || 0} active />
                    <small style={{ color: "rgba(255,255,255,.85)" }}>
                      {" / "}
                      <Timer time={phase?.session?.timers?.TOTAL || 0} active />
                    </small>
                  </Tag>
                </div>
              </div>
              <div className="columns">
                <div className="column has-text-left">
                  <h4 className="title is-4 w500">{machine.name}</h4>
                  <div
                    className="separator"
                    style={{ marginBottom: "1.5rem" }}
                  />
                  <MachineParameters parameters={machine.parameters} />
                </div>
              </div>
            </>
          </Background>
        </ThemeProvider>
      </SetStatus>
    </>
  );
}

Machine.propTypes = {};
export default Machine;
