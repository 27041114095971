import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import styled from "styled-components";

import Docs from "../../../../components/Docs";
import Notes from "../../../../components/Notes";
import StepHeader from "../../../../components/StepHeader";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import { buttons } from "../../../../libs/themes";
import Hoverable from "../../../application/components/hoverable";

const Column = styled.div`
  max-width: 100%;
`;

function Phase({ phase }) {
  return (
    <>
      <div className="columns">
        <Column className="column">
          <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
            <FormattedMessage id="app.shared.docs" defaultMessage="Docs" />
          </SmallTag>
          <Hoverable text={phase.sort + " :: " + phase.name}>
            <Title.H1 isEllipsed>
              {phase.sort} :: {phase.name}
            </Title.H1>
          </Hoverable>
          <StepHeader />
        </Column>
      </div>
      <div className="columns">
        <div className="column is-half has-text-left">
          <Docs filterFn={(doc) => !["CHECK"].includes(doc.type)} />
        </div>
        <div className="column is-half has-text-left">
          <Notes
            orderDescription={phase.job.description}
            operationDescription={phase.description}
            sessions={phase.sessions}
          />
        </div>
      </div>
    </>
  );
}

Phase.defaultProps = {};
Phase.propTypes = {
  phase: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    job: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      docs: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          mimeType: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      docs: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          mimeType: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        mimeType: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
export default compose()(Phase);
