import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { setFlow, setNotes, setStatus, setTime } from "../../../../actions";
import Button from "../../../../components/Button";
import CircleElem from "../../../../components/CircleElem";
import ListElement from "../../../../components/ListElement";
import themes, { statuses } from "../../../../libs/themes";

const LineButton = styled(Button)`
  height: 6.8rem;
  font-size: 3rem;
`;

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

function getInitialData(session, type) {
  return {
    time: getTimeFromDate(session[type]),
    isYesterday: dateStringIsYesterday(session[type]),
  };
}

function getTimeFromDate(dateString) {
  if (dateString === null) {
    return "--";
  }
  return new Date(dateString).toLocaleTimeString("it-IT", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
}

function dateStringIsYesterday(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  return (
    date.getDate() === today.getDate() - 1 &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function Session(props) {
  const session = props.session;
  console.log(session);
  const phase = session.phase;
  const dispatch = useDispatch();
  const history = useHistory();

  const [startValue, setStartValue] = useState(
    getInitialData(session, "start")
  );
  const [endValue, setEndValue] = useState(getInitialData(session, "end"));

  const isEmpty = session == null || session._id == null;

  useEffect(() => {
    setStartValue(getInitialData(session, "start"));
    setEndValue(getInitialData(session, "end"));
  }, [session]);

  function onClick() {
    dispatch(
      setTime({
        start: session.start,
        end: session.end,
      })
    );
    if (isEmpty) {
      dispatch(setFlow("MANUAL_SESSION"));
      dispatch(setStatus("INTERRUPT"));
      history.push("/configuration/add-operations");
    } else {
      dispatch(setStatus(session.status));
      dispatch(setNotes(session.notes));
      history.push(
        `/worker/manual-session/time/${session.phase._id}/${session._id}`
      );
    }
  }

  if (phase == null) {
    return null;
  }

  return (
    <>
      <ListElement
        theme={
          statuses[session.status]?.listElement ?? themes.default.listElement
        }
        data-cy="configuration-operations-operation"
        data-cy-id={session._id}
        icon={
          phase.product && phase.product.image
            ? phase.product.image
            : phase.job?.product?.image
        }
      >
        <div className="columns is-vcentered is-fullwidth is-marginless">
          <div className="column">
            <p className="subtitle is-6">
              <CircleElem
                theme={
                  statuses[session.status]?.listElement ??
                  themes.default.listElement
                }
              >
                <FormattedMessage
                  id={
                    "app.components.phase.status." + (session.status ?? "NEW")
                  }
                  defaultMessage=""
                />
              </CircleElem>
              {phase.job != null && (
                <Badge>
                  <i className="icon-sb-job-board-icn" />
                  {phase.job.name}
                </Badge>
              )}
              {phase.station != null && (
                <Badge>
                  <i className="icon-st-areas-icn" />
                  {phase.station.name}
                </Badge>
              )}
              {phase.product && phase.product.code && (
                <Badge>
                  <i className="icon-product" />
                  {phase.product.code}
                </Badge>
              )}
              {phase.job && phase.job.salesOrderId && (
                <Badge>
                  <i className="icon-doc-text" />
                  {phase.job.salesOrderId}
                </Badge>
              )}
            </p>
            <p className="title is-3">{phase.name}</p>
          </div>
          <div className="column is-1">
            <ul>
              <li className="subtitle is-5 fg-secondary">
                <FormattedMessage
                  id="session.start"
                  defaultMessage={`Inizio`}
                />
              </li>
              <li className="title is-4">{startValue.time}</li>
            </ul>
          </div>
          <div className="column is-1">
            <ul>
              <li className="subtitle is-5 fg-secondary">
                <FormattedMessage id="session.end" defaultMessage={`Fine`} />
              </li>
              <li className="title is-4 ">{endValue.time}</li>
            </ul>
          </div>
          <div className="column is-narrow" style={{ textAlign: "right" }}>
            <LineButton
              onClick={onClick}
              className="button"
              theme={themes.primary.buttons}
              on
            >
              {isEmpty ? (
                <FormattedMessage
                  id="app.pages.configuration.operations.add"
                  defaultMessage="Add"
                />
              ) : (
                <FormattedMessage
                  id="app.pages.configuration.operations.edit"
                  defaultMessage="Edit"
                />
              )}
            </LineButton>
          </div>
        </div>
      </ListElement>
    </>
  );
}

Session.defaultProps = {};
Session.propTypes = {};
export default Session;
