import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

function NumberFormatter({ value }) {
  if (isNaN(value)) {
    return <span>-</span>;
  }
  return (
    <FormattedNumber
      minimumFractionDigits={value % 1 === 0 ? 0 : 2}
      maximumFractionDigits={value % 1 === 0 ? 0 : 2}
      value={value || 0}
    />
  );
}

NumberFormatter.defaultProps = {};
NumberFormatter.propTypes = {
  value: PropTypes.number.isRequired,
};
export default NumberFormatter;
