import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import Title from "../../../../../components/Title";
import { MediumButton } from "../../../../../components/Button";
import OnScreenKeyboard from "../../../../../components/OnScreenKeyboard";
import themes, { buttons } from "../../../../../libs/themes";
import Subtitle from "../../../../../components/Subtitle";

function ContainerUnloadStep2({
  notes,
  setNotes,
  container,
  onSubmit,
  setStep,
}) {
  const intl = useIntl();
  const inputEl = useRef(null);
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <Title.H1>
            <FormattedMessage
              id="app.pages.application.container_unload.notes.title"
              defaultMessage="Do you want to add a note?"
            />
          </Title.H1>
          <Subtitle.S3
            theme={themes.secondary}
            style={{ marginBottom: ".5rem" }}
          >
            {container.name}
          </Subtitle.S3>
          {container.item && (
            <Subtitle.S4 theme={themes.secondary}>
              {container.item.code} :: {container.item.name}
            </Subtitle.S4>
          )}
        </div>
        <div className="column is-3">
          <div className="columns">
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.default}
                onClick={() => setStep(1)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.back" defaultMessage="Back" />
              </MediumButton>
            </div>
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.productionBlue}
                onClick={onSubmit}
                data-cy="application-button-ok"
              >
                <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
              </MediumButton>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="separator" />
          <textarea
            name="notes"
            data-cy="application-container_unload-notes-input"
            id="notes"
            rows="6"
            className="title is-4"
            value={notes}
            autoFocus
            placeholder={intl.formatMessage({
              id: "app.components.notes.placeholder",
            })}
            onFocus={() => inputEl.current && inputEl.current.select()}
            ref={inputEl}
            onInput={(event) => setNotes(event.target.value)}
          />
        </div>
      </div>
      <OnScreenKeyboard inputNode={inputEl.current} />
    </>
  );
}

ContainerUnloadStep2.defaultProps = {
  notes: null,
};
ContainerUnloadStep2.propTypes = {
  notes: PropTypes.string,
  setNotes: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContainerUnloadStep2;
