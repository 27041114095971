import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import SelectField from "../../../../../components/Fields/SelectField";
import HmiField from "../../../../../components/HmiField/HmiField";

function ContainerLoadStep4({
  supplier,
  setSupplier,
  suppliers,
  container,
  setStep,
}) {
  const intl = useIntl();
  return (
    <HmiField
      title={intl.formatMessage({
        id: "app.pages.application.container_supplier.title",
      })}
      onNext={() => {
        setStep(5);
      }}
      onBack={() => {
        setStep(3);
      }}
      field={
        <SelectField
          key="supplier"
          placeholder={supplier}
          defaultValue={supplier}
          onChange={(value) => {
            setSupplier(value);
          }}
          options={suppliers.map((s) => {
            return { value: s._id, text: s.name };
          })}
        />
      }
    />
  );
}

ContainerLoadStep4.defaultProps = {
  supplier: null,
};
ContainerLoadStep4.propTypes = {
  supplier: PropTypes.number,
  setSupplier: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerLoadStep4;
