import PropTypes from "prop-types";
import React from "react";

import TextField from "../../../../../components/Fields/TextField";
import HmiField from "../../../../../components/HmiField/HmiField";
import { useIntl } from "react-intl";

function ContainerLoadStep3({ batch, setBatch, container, setStep }) {
  const intl = useIntl();
  return (
    <HmiField
      title={intl.formatMessage({
        id: "app.pages.application.container_load.batch.title",
        defaultMessage: "Inserisci lotto",
      })}
      subtitle={container.name}
      subtitle2={
        container.item && `${container.item.code} :: ${container.item.name}`
      }
      field={
        <TextField
          key="batch"
          placeholder={batch || ""}
          defaultValue={batch || ""}
          onChange={(value) => {
            setBatch(value);
          }}
        />
      }
      value={batch}
      onBack={() => setStep(2)}
      onNext={() => setStep(4)}
    />
  );
}

ContainerLoadStep3.defaultProps = {
  scrap: null,
};
ContainerLoadStep3.propTypes = {
  batch: PropTypes.number,
  setBatch: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerLoadStep3;
