import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import SearchField from "../../../../../components/Fields/SearchField";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import OnScreenKeyboard from "../../../../../components/OnScreenKeyboard";
import Title from "../../../../../components/Title";
import GenericError from "../../../../errors/GenericError";
import Item from "./Item";

const CONTAINER_ITEMS_PAGED = loader("./ContainerItemStep1.query.graphql");

function ContainerItemStep1({ container, itemId, setItemId, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const { containerId } = useParams();
  const [search, setSearch] = useState("");
  const [showKeyboard] = useState(false);

  const variables = { containerId, options: { offset: 0, limit: 25 }, search };
  const { loading, error, data } = useQuery(CONTAINER_ITEMS_PAGED, {
    variables,
  });
  if (loading && !data && search == "") {
    return <LoadingIndicator />;
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  function onClick(itemId) {
    setItemId(itemId);
    setStep(2);
  }

  const { result } = data ?? { result: { total: 0, items: [] } };
  return (
    <>
      {/* HEAD */}
      <div className="columns">
        <div className="column has-text-centered">
          <h1 className="title is-1 w700">
            <FormattedMessage
              id="app.pages.application.container_item.title"
              defaultMessage="Choose item ({value, number} found)"
              values={{ value: result.total }}
            />
          </h1>
        </div>
      </div>
      <div className="columns">
        <div className="column is-6 is-offset-3">
          <SearchField
            placeholder={intl.formatMessage({
              id: "app.pages.application.container_item.search.placeholder",
              defaultMessage: "Search item...",
            })}
            defaultValue={search}
            onChange={(value) => setSearch(value)}
          />
        </div>
      </div>
      {/* DATA */}
      <div className="columns">
        <div className="column is-10 is-offset-1">
          {result.items.map((item) => (
            <Item
              key={item._id}
              item={item}
              onClick={onClick}
              selected={itemId || container._item}
            />
          ))}
          {result.items === 0 && (
            <Title.H2>
              <FormattedMessage
                id="app.pages.application.container_item.item.empty"
                defaultMessage="There is no item, contact responsible"
              />
            </Title.H2>
          )}
        </div>
      </div>
      {showKeyboard && <OnScreenKeyboard inputNode={inputEl.current} />}
    </>
  );
}

ContainerItemStep1.defaultProps = {
  itemId: null,
};
ContainerItemStep1.propTypes = {
  itemId: PropTypes.string,
  setItemId: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    _item: PropTypes.string,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ContainerItemStep1;
