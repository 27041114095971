import { useQuery } from "@apollo/client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout/Background";
import themes from "../../../libs/themes";
import SetupBody from "../components/setup/SetupBody";
import Wrapper from "./components/Wrapper";
import HasFeature from "../../../components/HasFeature";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";

const SETUP = loader("./Setup.query.graphql");

function Setup() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  const variables = { sessionId };
  const { data, loading, error } = useQuery(SETUP, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;
  //debugger;
  return (
    <Wrapper status="SETUP">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* FIRST BUTTON */}
            <div className="column is-4">
              {/* MENU BUTTON */}
              <Button
                isFullWidth
                onClick={() =>
                  history.push(`/application/menu/${phaseId}/${sessionId}`)
                }
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            {/* SECOND BUTTON */}
            <div className="column is-4">
              {/* SETUP MACHINE BUTTON */}
              <HasFeature feature="MACHINE:INTEGRATION">
                {session._machine && (
                  <Button
                    isFullWidth
                    theme={themes.setupYellow.buttons}
                    onClick={() =>
                      history.push(
                        `/application/machine/${phaseId}/${sessionId}/${session._machine}`
                      )
                    }
                    data-cy="application-button-machine"
                  >
                    <FormattedMessage
                      id="app.pages.application.setup.buttons.machine"
                      defaultMessage="Machine"
                    />
                  </Button>
                )}
              </HasFeature>
            </div>
            {/* THIRD BUTTON */}
            <div className="column is-4">
              <Button
                isFullWidth
                theme={themes.checkGreen.buttons}
                onClick={() =>
                  history.push(`/application/approval/${phaseId}/${sessionId}`)
                }
                data-cy="application-button-next"
              >
                <FormattedMessage
                  id="app.shared.approval"
                  defaultMessage="Approval"
                />
              </Button>
            </div>
          </div>
        }
      >
        <SetupBody />
      </Background>
    </Wrapper>
  );
}

Setup.defaultProps = {};
Setup.propTypes = {};
export default Setup;
