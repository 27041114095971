import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import React from "react";
import styled from "styled-components";

import onError from "../../../libs/error-logger";
import intl from "../../../libs/format-message";
import toast from "../../../libs/toast";
import Button from "../../Button";

const CONTAINER_ACTION = loader("./ContainerAction.mutation.graphql");

const LineButton = styled(Button)`
  width: 100%;
  font-size: 120%;
`;

const RemoveButton = styled(Button)`
  width: 100%;
  background-color: #fff;
  color: black;
  font-size: 120%;
  text-decoration: none !important;
  &:hover {
    background-color: #ccc;
    color: black;
  }
`;

function ContainerAction({
  children,
  action,
  container,
  type = "primary",
  fgColor = "#fff",
}) {
  const [mutate] = useMutation(CONTAINER_ACTION);

  async function containerAction(containerId, action) {
    try {
      await mutate({ variables: { containerId, action } });
      toast({ title: intl.formatMessage({ id: "app.shared.action_sent" }) });
    } catch (error) {
      onError(error);
    }
  }
  return (
    <div className="column">
      {type === "primary" && (
        <LineButton
          isSmall={true}
          onClick={(e) => {
            e.stopPropagation();
            containerAction(container._id, action);
          }}
          data-cy="application-button-picked"
        >
          {children}
        </LineButton>
      )}
      {type === "secondary" && (
        <RemoveButton
          isSmall={true}
          onClick={(e) => {
            e.stopPropagation();
            containerAction(container._id, action);
          }}
          data-cy="application-button-picked"
        >
          {children}
        </RemoveButton>
      )}
    </div>
  );
}

ContainerAction.defaultProps = {};
ContainerAction.propTypes = {};
export default ContainerAction;
