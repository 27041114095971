import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";

import { setLoading } from "../../../../actions";
import TextField from "../../../../components/Fields/TextField";
import Header from "../../../../components/Header";
import HmiField from "../../../../components/HmiField/HmiField";
import Background from "../../../../components/Layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import GenericError from "../../../errors/GenericError";

const CONTAINER_LOAD = loader("./ContainerLoad.query.graphql");
const CONTAINER_CONTENT = loader("./ContainerContent.mutation.graphql");

function ContainerRfid() {
  const intl = useIntl();
  const history = useHistory();

  const { containerId } = useParams();
  const dispatch = useDispatch();

  const variables = { containerId };
  const [smiroCode, setSmiroCode] = useState(null);

  const { loading, error, data } = useQuery(CONTAINER_LOAD, { variables });
  const [mutate] = useMutation(CONTAINER_CONTENT, { variables });

  const theme = themes.default;

  async function onSubmit() {
    if (smiroCode != null && smiroCode.trim() != null) {
      dispatch(setLoading(true));
      try {
        await mutate({ variables: { containerId, smiroCode } });
        toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
        history.goBack();
      } catch (error) {
        onError(error);
      }
      dispatch(setLoading(false));
    }
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }
  const { container } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Load - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
        >
          <HmiField
            title={intl.formatMessage({
              id: "app.pages.application.container_rfid.qty.title",
              defaultMessage: "Associa un tag",
            })}
            subtitle={container.name}
            onNext={onSubmit}
            onBack={() => history.goBack()}
            field={
              <TextField
                key="smiroCode"
                autofocus={true}
                placeholder={data.container.smiroCode}
                defaultValue={data.container.smiroCode}
                onChange={(value) => {
                  setSmiroCode(value);
                }}
              />
            }
          />
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerRfid.defaultProps = {};
ContainerRfid.propTypes = {};

export default ContainerRfid;
