import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function hasFeature(feature, enabledFeatures) {
  const enabled = enabledFeatures.some((featureName) => {
    const regex = new RegExp(feature);
    return regex.test(featureName);
  });
  return enabled;
}

/**
 * Checks if feature is enabled
 *
 * @param feature
 * @param children
 * @return {*}
 * @constructor
 */
function HasFeature({ feature, children }) {
  const { features } = useSelector(({ config }) => config);
  // Check if feature is enabled with regex
  if (hasFeature(feature, features)) {
    return <>{children}</>;
  }
  return <span />;
}

HasFeature.defaultProps = {};
HasFeature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default HasFeature;

export { hasFeature };
