import React from "react";
import styled from "styled-components";

const StyledFootContainer = styled.div`
  //padding: 0 3rem 3rem;
  padding: 1.5rem 1.5rem;
  //min-height: 12rem;
  //@media screen and (max-height: 640px) {
  //  padding: 0 1.5rem 1.5rem;
  //}
`;

function FootContainer({ children, ...props }) {
  return (
    <StyledFootContainer className="hero-foot" {...props}>
      {children}
    </StyledFootContainer>
  );
}

FootContainer.defaultProps = {};
FootContainer.propTypes = {};
export default FootContainer;
