import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import ListElement from "../../../../components/ListElement";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import StepHeader from "../../../../components/StepHeader";
import TableHeader from "../../../../components/TableHeader";
import Tag, { SmallTag } from "../../../../components/Tag";
import Timer from "../../../../components/Timer";
import Title from "../../../../components/Title";
import themes from "../../../../libs/themes";
import Hoverable from "../hoverable";
import { setContainerActionOrigin } from "../../../../actions";
import NumberFormatter from "../../../../libs/format-number";

const MATERIAL = loader("./MaterialBody.query.graphql");

/**
 * Material page
 *
 * @param stepName
 * @constructor
 */

const Column = styled.div`
  min-width: 0;
`;

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 1.2rem 0.4rem 0.9rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.main {
    color: #d9e0ec;
    background-color: #4a4a4a;
  }
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
  .progress {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.14);
    z-index: -1;
  }
`;

const Counter = styled.div`
    width: 17.4rem !important;
    text-overflow: ellipses;
    font-size: 2.8rem;
    height: 4.4rem;
    line-height: 2.8rem;
    display: flex;
    flex-direction: horizontal;
    align-items: baseline;
    padding-left: 1.8rem;
    &.has-right-border {
      border-right: 0.06rem solid rgba(0, 0, 0, 0.15)
    }
    &:last-child {
      width: 26.4rem !important;
      height auto;
      justify-content: start;
      padding-left: 3.2rem;
    }
    span.unit{
      font-size: 1.8rem;
    }
    .container-content {
      background-color: #DAE1ECaa;
      padding: 1.2rem 2.4rem;
      border-radius: 2.8rem;
      font-size: 1.8rem;
      color: rgba(0,0,0,0.7);
      span.content{
        font-size: 2.4rem;
        font-weight: 700;
        color: #000;
      }
      &.error {
        //background-color: #F8D6E2ee;
        span.content{
          color: #D9355B;
        }
        span.nan{
          color: #D9355B;
          font-size: 1.6rem;
        }
      }
      span.unit{
        font-size: 1.8rem;
      }
    }
  `;

const TableHeadCounter = styled.div`
  width: 17.4rem !important;
  font-size: 2.8rem;
  line-height: 2.8rem;
  text-overflow: ellipses;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  &.has-right-border {
    border-right: 0.06rem solid rgba(0, 0, 0, 0.15);
  }
  &:last-child {
    width: 27.4rem !important;
    text-align: left;
    padding-left: 3.4rem;
  }
`;

function MaterialBody({ stepName }) {
  const { phaseId, sessionId, stepId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(MATERIAL, { variables });

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { SHOW_ADD_PICKING_ITEM } = hmiConfiguration;

  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session, phase } = data;
  let remaining = Math.max(0, phase.qty - phase.produced);

  return (
    <>
      <Helmet>
        <title>Material - Agile Factory</title>
      </Helmet>
      <div className="columns">
        <Column className="column">
          <SmallTag isUpperCase isLight theme={themes.materialViolet.buttons}>
            {stepName || (
              <FormattedMessage
                id="app.shared.material"
                defaultMessage="Material"
              />
            )}
          </SmallTag>
          <Hoverable text={phase.sort + " :: " + phase.name}>
            <Title.H1 isEllipsed>
              {phase.sort} :: {phase.name}
            </Title.H1>
          </Hoverable>
          <StepHeader />
        </Column>

        <div className="column is-narrow">
          <Tag theme={themes.materialViolet.buttons}>
            <i className="icon-giphy-time" />
            <Timer time={session.timers.MATERIAL || 0} active />
            <small style={{ color: "rgba(255,255,255,.85)" }}>
              {" / "}
              <Timer time={session.timers.TOTAL || 0} active />
            </small>
          </Tag>
        </div>
      </div>

      {session.picking.length > 0 && (
        <>
          <h4 className="title is-4 w500 has-10-margin-top">
            <FormattedMessage
              id="app.components.docs.doc_default.doc.PICKING"
              defaultMessage="Picking list"
            />
          </h4>
          <div className="separator" />
          <div className="columns">
            {/* Picking, row for each phase.picking */}
            <div className="column">
              <br />
              <div className="columns is-multiline">
                <TableHeader>
                  <div className="column">
                    <p className="title is-5">
                      <FormattedMessage
                        id="app.pages.application.material.table.item"
                        defaultMessage="Item"
                      />
                    </p>
                  </div>
                  <TableHeadCounter className="column is-narrow has-right-text ">
                    <p className="title is-5">
                      <FormattedMessage
                        id="app.pages.application.material.table.qty"
                        defaultMessage="Qty"
                      />
                    </p>
                  </TableHeadCounter>
                  <TableHeadCounter className="column is-narrow has-right-text">
                    <p className="title is-5">
                      <FormattedMessage
                        id="app.pages.application.material.table.tot_qty"
                        defaultMessage="Qty total"
                      />
                    </p>
                  </TableHeadCounter>
                  <TableHeadCounter className="column is-narrow has-right-text">
                    <p className="title is-5">
                      <FormattedMessage
                        id="app.pages.application.material.table.container_qty"
                        defaultMessage="Qty container"
                      />
                    </p>
                  </TableHeadCounter>
                </TableHeader>
                {session.picking.map((picking, index) => {
                  var theme = picking.container
                    ? themes.default.listElement
                    : themes.errorRed.listElement;

                  var mainItemBadge = picking.main ? (
                    <Badge className="main">
                      <i className="icon-star-empty" />
                      <FormattedMessage
                        id="app.pages.application.material.table.main"
                        defaultMessage="Main item"
                      />
                    </Badge>
                  ) : (
                    <></>
                  );

                  var containerBadge =
                    picking.container && !picking.container?.isVirtual ? (
                      <Badge>
                        <i className="icon-st-containers-icn" />
                        {picking.container.name}
                      </Badge>
                    ) : !picking.container?.isVirtual ? (
                      <Badge className="error">
                        <i className="icon-st-containers-icn" />
                        <FormattedMessage
                          id="app.pages.application.material.table.container.nan"
                          defaultMessage="Seleziona container"
                        />
                      </Badge>
                    ) : (
                      <></>
                    );

                  var batchBadge = picking.container?.batch ? (
                    <Badge>
                      <i className="icon-doc-text" />
                      {picking.container.batch.code}
                    </Badge>
                  ) : (
                    <Badge className="error">
                      <i className="icon-doc-text" />
                      <FormattedMessage
                        id="app.pages.application.material.table.batch.na"
                        defaultMessage="Inserisci lotto"
                      />
                    </Badge>
                  );

                  var articleBadge =
                    picking.item._id &&
                    picking.container?._item &&
                    picking.item._id == picking.container?._item ? (
                      <Badge>
                        <i className="icon-product" />
                        {picking.item.name}
                      </Badge>
                    ) : (
                      <Badge className="error">
                        <i className="icon-product" />
                        <FormattedMessage
                          id="app.pages.application.material.table.article.nan"
                          defaultMessage="Articolo errato"
                        />
                      </Badge>
                    );
                  const sumCurrent = picking.containers.reduce(
                    (acc, container) => acc + container.current,
                    0
                  );
                  return (
                    <ListElement
                      onClick={() => {
                        dispatch(setContainerActionOrigin("material"));
                        return history.push(
                          `/application/supply-item/${phaseId}/${sessionId}/${picking._id}/${stepId}`
                        );
                      }}
                      theme={theme}
                      data-cy="application-supply-item"
                      data-cy-id={picking._id}
                      icon={picking.item.image}
                      notes={picking.notes}
                      index={index + 1}
                    >
                      <div className="columns is-vcentered is-fullwidth is-marginless">
                        <div
                          className="column"
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: "calc(100vw - 85rem)",
                          }}
                        >
                          <p className="subtitle is-6">
                            {mainItemBadge}
                            {containerBadge}
                            {batchBadge}
                            {articleBadge}
                          </p>
                          <p
                            className="title is-4"
                            style={{ fontWeight: "500" }}
                          >
                            {picking.item.code} :: {picking.item.name}
                          </p>
                        </div>
                        <Counter className="column is-narrow">
                          {picking.item && (
                            <>
                              <NumberFormatter value={picking.qty} />
                              &nbsp;
                              {picking.item.unit && (
                                <span className="unit">
                                  {picking.item.unit}
                                </span>
                              )}
                            </>
                          )}
                        </Counter>
                        <Counter className="column is-narrow">
                          {picking.item && (
                            <>
                              <NumberFormatter
                                value={picking.qty * remaining}
                              />
                              &nbsp;
                              {picking.item.unit && (
                                <span className="unit">
                                  {picking.item.unit}
                                </span>
                              )}
                            </>
                          )}
                        </Counter>
                        <Counter className="column is-narrow">
                          <div
                            className={
                              "container-content" +
                              (remaining * picking.qty > sumCurrent
                                ? " error"
                                : "")
                            }
                          >
                            {picking.container ? (
                              <>
                                <span className={"content"}>
                                  <NumberFormatter value={sumCurrent} />
                                </span>
                                {picking.container.capacity && (
                                  <>
                                    {" "}
                                    /{" "}
                                    <NumberFormatter
                                      value={picking.container.capacity}
                                    />
                                  </>
                                )}
                                {picking.item.unit && (
                                  <span className="unit">
                                    {" "}
                                    {picking.item.unit}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="nan">
                                <FormattedMessage
                                  id="app.pages.application.material.table.container.nan"
                                  defaultMessage="Seleziona container"
                                />
                              </span>
                            )}
                          </div>
                        </Counter>
                      </div>
                    </ListElement>
                  );
                })}
                {/* new item listelement*/}
                {SHOW_ADD_PICKING_ITEM && (
                  <ListElement
                    onClick={() =>
                      history.push(
                        `/application/withdraw-item/material/${phaseId}/${sessionId}/${stepId}`
                      )
                    }
                    theme={themes.neutralGrey.listElement}
                    isNew={true}
                    data-cy="application-withdraw-item"
                    icon={null}
                    withCircle={true}
                  >
                    <div className="columns is-vcentered is-fullwidth is-marginless">
                      <div
                        className="column"
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "calc(100vw - 85rem)",
                        }}
                      >
                        <p className="title is-4">
                          <FormattedMessage
                            id="app.pages.application.material.buttons.add_item"
                            defaultMessage="Add item"
                          />
                        </p>
                      </div>
                    </div>
                  </ListElement>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

MaterialBody.defaultProps = {
  stepName: "MATERIAL",
};
MaterialBody.propTypes = {
  stepName: PropTypes.string,
};

export default MaterialBody;
