import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useQuery } from "@apollo/client";

import Title from "../../../components/Title";
import Docs from "../../../components/Docs";
import Tag, { SmallTag } from "../../../components/Tag";
import Timer from "../../../components/Timer";
import Notes from "../../../components/Notes";
import Button from "../../../components/Button";
import themes, { buttons } from "../../../libs/themes";
import Background from "../../../components/Layout";
import { setStatus } from "../../../actions";
import Header from "../../../components/Header";
import StepHeader from "../../../components/StepHeader";

import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Hoverable from "../components/hoverable";

import config from "../../../config";
import { loader } from "graphql.macro";
const DELIVERY = loader("./Delivery.query.graphql");

const Column = styled.div`
  max-width: 100%;
`;

function Delivery() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(DELIVERY, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;

  const theme = themes.neutralGrey;
  const { PARTIAL_DELIVERY } = phase.configuration;

  const machine = phase.machine;

  return (
    <>
      <Helmet>
        <title>Delivery - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                {/* MENU BUTTON */}
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../images/arrow-back-gray-icn.svg").default
                    }
                    alt=""
                  />
                </Button>
              </div>
              {/* PARTIAL DELIVERY */}
              {PARTIAL_DELIVERY && (
                <div className="column is-5">
                  <Button
                    isFullWidth
                    theme={themes.primary.buttons}
                    onClick={() => {
                      dispatch(setStatus("INTERRUPT"));
                      if (session.hasSerialnumbers)
                        return history.push(
                          `/application/picked/${phaseId}/${sessionId}`
                        );
                      history.push(
                        `/application/produced/${phaseId}/${sessionId}`
                      );

                      return;
                    }}
                    data-cy="application-button-delivery-partial"
                  >
                    <FormattedMessage
                      id="app.pages.application.deliver.buttons.partial_button"
                      defaultMessage="Partial delivery"
                    />
                  </Button>
                </div>
              )}
              {/* FINAL BUTTON */}
              <div
                className={classNames(
                  "column",
                  PARTIAL_DELIVERY ? "is-5" : "is-6 is-offset-2"
                )}
              >
                <Button
                  isFullWidth
                  theme={themes.productionBlue.buttons}
                  onClick={() => {
                    dispatch(setStatus("DONE"));
                    if (session.hasSerialnumbers)
                      return history.push(
                        `/application/picked/${phaseId}/${sessionId}`
                      );
                    history.push(
                      `/application/produced/${phaseId}/${sessionId}`
                    );

                    return;
                  }}
                  data-cy="application-button-delivery-final"
                >
                  <FormattedMessage
                    id="app.pages.application.deliver.buttons.final_button"
                    defaultMessage="Final delivery"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <Column className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <FormattedMessage
                    id="app.shared.delivery"
                    defaultMessage="Delivery"
                  />
                </SmallTag>
                <Hoverable text={phase.sort + " :: " + phase.name}>
                  <Title.H1 isEllipsed>
                    {phase.sort} :: {phase.name}
                  </Title.H1>
                </Hoverable>
                <StepHeader />
              </Column>
              <div className="column is-narrow">
                <Tag theme={buttons.neutralGrey}>
                  <i className="icon-giphy-time" />
                  <Timer time={session.timers.TOTAL || 0} active />
                </Tag>
              </div>
            </div>
            <div className="columns">
              <div className="column is-half has-text-left">
                <Docs
                  show3dScan={
                    config.customer === "it-sssn" &&
                    machine &&
                    machine.name == "Scanner 3D"
                  }
                />
              </div>
              <div className="column is-half has-text-left">
                <Notes />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Delivery.propTypes = {};

export default Delivery;
