import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { ThemeProvider } from "styled-components";

import Title from "../../../components/Title";
import Subtitle from "../../../components/Subtitle";
import Button from "../../../components/Button";
import Background from "../../../components/Layout";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import { setStation } from "../../../actions";
import Header from "../../../components/Header";
import Clock from "../../../components/Clock";
import Stations from "./components/Stations";

import themes from "../../../libs/themes";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { loader } from "graphql.macro";
const STATIONS = loader("./ChooseStation.query.graphql");

function ChooseStation() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, config } = useSelector(({ user, config }) => ({
    user,
    config,
  }));
  const hmiId = config.hmiId;

  const { data, loading, error } = useQuery(STATIONS, { variables: { hmiId } });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { stations } = data;

  function onClick(stationId) {
    dispatch(setStation(stationId));
    if (!user || !user.worker) {
      return history.push("/configuration/operator");
    }
    return history.push("/configuration/order");
  }

  function onBack() {
    if (config.flow === "STATION") {
      return history.push("/");
    }
    return history.push("/configuration/add-operations");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choose station - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.stations.title"
                    defaultMessage="Choose station"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.stations.subtitle"
                    defaultMessage="Tell me where you are"
                  />
                </Subtitle.S3>
              </div>
            </div>
            <Stations onClick={onClick} stations={stations} />
            {stations.length === 0 && (
              <Title.H2>
                <FormattedMessage
                  id="app.pages.configuration.stations.empty"
                  defaultMessage="Sorry, no stations assigned to this HMI, contact a responsible"
                />
              </Title.H2>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ChooseStation.defaultProps = {
  loading: true,
};
ChooseStation.propTypes = {};

export default ChooseStation;
