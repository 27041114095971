import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import classNames from "classnames";
import { useIntl } from "react-intl";
import Avatar from "../../../../../components/Avatar";
import confirmAction from "../../../../../libs/confirm_action";
import { buttons } from "../../../../../libs/themes";
import ContainerMovementType from "../../containers/components/ContainerMovementType";

const StyledWrapper = styled.div`
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  .status {
    transition: opacity 250ms ease-in-out;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      height: 2rem;
      width: 2rem;
    }

    &.is-output {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.productionBlue.fg};
      background-color: ${buttons.productionBlue.bg};
    }

    &.is-input {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.materialViolet.fg};
      background-color: ${buttons.materialViolet.bg};
    }

    &.is-scrap {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.errorRed.fg};
      background-color: ${buttons.errorRed.bg};
    }
  }

  > .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }

  .image,
  a {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

function Container({ container, onClick, session }) {
  const intl = useIntl();

  let type = "normal";
  if (session._containerOutput === container._id) {
    type = "output";
  } else if (session._containerScrap === container._id) {
    type = "scrap";
  } else if (session.picking.map((p) => p._container).includes(container._id)) {
    type = "input";
  }
  const enabled = container.activeSession == null;
  return (
    <StyledWrapper
      className={`column is-3 has-text-centered ${!enabled && "disabled"}`}
    >
      <figure className="image" style={{ marginBottom: 8 }}>
        <a
          onClick={() => {
            if (type != "normal") {
              return confirmAction({
                confirm: () => {
                  onClick(container._id);
                },
                options: {
                  text: intl.formatMessage({
                    id: "app.pages.application.container_choose.already_in_use",
                    defaultMessage: "Warning, container already in use",
                  }),
                },
              });
            }
            onClick(container._id);
          }}
          data-cy="application-containers-container"
          data-cy-id={container._id}
        >
          <Avatar size="12.8rem" name={container.name} src={container.image} />
        </a>
        <div className={classNames("status", `is-${type}`)}>
          <ContainerMovementType type={type} size="24rem" />
        </div>
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{container.name}</h4>
      {container.item && (
        <p className="subtitle is-4 fg-secondary has-text-centered">
          {container.item.code} <small>{container.item.name}</small>
        </p>
      )}
    </StyledWrapper>
  );
}

Container.defaultProps = {
  containerId: null,
};
Container.propTypes = {
  containerId: PropTypes.string,
  onClick: PropTypes.string.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
};
export default Container;
