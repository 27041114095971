import { useMutation, useQuery } from "@apollo/client";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";
import { darken } from "polished";

import ListElement from "../../../../components/ListElement";
import Button from "../../../../components/Button";
import OnScreenKeyboard from "../../../../components/OnScreenKeyboard";
import Title from "../../../../components/Title";
import onError from "../../../../libs/error-logger";
import toast from "../../../../libs/toast";
import { setLoading } from "../../../../actions";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../../libs/themes";
import GenericError from "../../../errors/GenericError";

const selectedTheme = {
  ...themes.default.listElement,
  bg: darken(0.05, themes.default.listElement.bg),
};

const CONTAINER_LOAD = loader("./ContainerLoad.query.graphql");
const SET_BATCH_SUPPLIER = loader("./ContainerBatchSupplier.mutation.graphql");

function ContainerBatchSupplier() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const history = useHistory();
  const [supplier, setSupplier] = useState();
  const [search, setSearch] = useState("");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const { containerId } = useParams();
  const dispatch = useDispatch();

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD } = hmiConfiguration;
  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_LOAD, { variables });
  const [mutate] = useMutation(SET_BATCH_SUPPLIER);

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      await mutate({
        variables: { batchId: container.batch._id, supplierId: supplier },
      });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }

  const { container, suppliers } = data;
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Load - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-3">
                <Button
                  isFullWidth
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <FormattedMessage
                    id="app.shared.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
              <div className="column is-3 is-offset-6">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  data-cy="application-button-save"
                  onClick={onSubmit}
                >
                  <FormattedMessage
                    id="app.shared.save"
                    defaultMessage="Save"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.container_load.supplier.title"
                    defaultMessage="Seleziona fornitore"
                  />
                </Title.H1>
                {/*<Subtitle.S3
                  theme={themes.secondary}
                  style={{ marginBottom: ".5rem" }}
                >
                  {container.name}
                </Subtitle.S3>
                {container.item && (
                  <Subtitle.S4 theme={themes.secondary}>
                    {container.item.code} :: {container.item.name}
                  </Subtitle.S4>
                )}*/}
              </div>
              {/*
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={() => history.goBack()}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-next"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
                </div>*/}
            </div>
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <input
                  type="text"
                  className="input is-rounded title is-3 is-double"
                  placeholder={intl.formatMessage({
                    id: "app.pages.application.container_item.search.placeholder",
                    defaultMessage: "Search item...",
                  })}
                  value={search}
                  onFocus={onFocus}
                  ref={inputEl}
                  onInput={(event) => setSearch(event.target.value)}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                {suppliers
                  .filter((s) =>
                    search != ""
                      ? s.name.toLowerCase().indexOf(search.toLowerCase()) !==
                        -1
                      : true
                  )
                  .map((s) => {
                    return (
                      <ListElement
                        onClick={() => setSupplier(s._id)}
                        theme={
                          supplier == s._id
                            ? selectedTheme
                            : themes.default.listElement
                        }
                        data-cy="application-container_item-item"
                        data-cy-id={s._id}
                        className={supplier == s._id ? "selected" : ""}
                        withCircle={false}
                      >
                        {s.name}
                      </ListElement>
                    );
                  })}
              </div>
            </div>
            {showKeyboard && <OnScreenKeyboard inputNode={inputEl.current} />}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerBatchSupplier.defaultProps = {};
ContainerBatchSupplier.propTypes = {};

export default ContainerBatchSupplier;
