import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import themes, { buttons } from "../../libs/themes";
import { MediumButton } from "../Button";
import Subtitle from "../Subtitle";
import Title from "../Title";

function HmiField({
  field,
  value,
  onNext,
  onBack,
  onSubmit,
  title,
  header,
  subtitle,
  subtitle2,
  defaultAction,
}) {
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <Title.H1>{title}</Title.H1>
          {subtitle && (
            <Subtitle.S3
              theme={themes.secondary}
              style={{ marginBottom: ".5rem" }}
            >
              {subtitle}
            </Subtitle.S3>
          )}
          {subtitle2 && (
            <Subtitle.S4 theme={themes.secondary}>{subtitle2}</Subtitle.S4>
          )}
        </div>
        <div className="column is-3">
          <div className="columns">
            {onBack && (
              <div className="column">
                <MediumButton
                  isFullWidth
                  theme={buttons.default}
                  onClick={onBack}
                  data-cy="application-button-back"
                >
                  <FormattedMessage
                    id="app.shared.back"
                    defaultMessage="Back"
                  />
                </MediumButton>
              </div>
            )}
            {onNext && (
              <div className="column">
                <MediumButton
                  isFullWidth
                  theme={buttons.productionBlue}
                  onClick={onNext}
                  data-cy="application-button-ok"
                >
                  <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                </MediumButton>
              </div>
            )}
            {onSubmit && (
              <div className="column">
                <MediumButton
                  isFullWidth
                  theme={buttons.productionBlue}
                  onClick={onSubmit}
                  data-cy="application-button-ok"
                >
                  <FormattedMessage
                    id="app.shared.done"
                    defaultMessage="Done"
                  />
                </MediumButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {field}
    </>
  );
}

HmiField.defaultProps = {};
HmiField.propTypes = {};

export default HmiField;
